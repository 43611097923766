import { createContextFactory } from '_utils';

export type SurveyState = { surveyId: string } & (
    | { screen: 'SelectApartment' }
    | { screen: 'SurveySelected'; goBack(): void }
    | { screen: 'SurveyQuestion'; questionIndex: number; goBack(): void }
    | { screen: 'SurveyComplete'; goBack(): void }
);

type action =
    | { type: 'openSurveySelected'; goBack(): void }
    | { type: 'openSelectApartment' }
    | { type: 'openSurveyQuestion'; questionIndex: number; goBack(): void }
    | { type: 'openSurveyComplete'; goBack(): void };

function reducer(state: SurveyState, action: action): SurveyState {
    switch (action.type) {
        case 'openSelectApartment':
            return { ...state, screen: 'SelectApartment' };
        case 'openSurveySelected':
            return { ...state, screen: 'SurveySelected', goBack: action.goBack };
        case 'openSurveyQuestion':
            return { ...state, screen: 'SurveyQuestion', questionIndex: action.questionIndex, goBack: action.goBack };
        case 'openSurveyComplete':
            return { ...state, screen: 'SurveyComplete', goBack: action.goBack };
    }
    return state;
}

const { Provider: SurveyProvider, useContextHook: useSurveyContext } = createContextFactory(reducer, (dispatch) => ({
    openSurveySelected: (goBack: () => void) => dispatch({ type: 'openSurveySelected', goBack }),
    openSelectApartment: () => dispatch({ type: 'openSelectApartment' }),
    openSurveyQuestion: (questionIndex: number, goBack: () => void) =>
        dispatch({ type: 'openSurveyQuestion', questionIndex, goBack }),
    openSurveyComplete: (goBack: () => void) => dispatch({ type: 'openSurveyComplete', goBack }),
}));

export { useSurveyContext, SurveyProvider };
