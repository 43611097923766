import { useEffect } from 'react';
import { track } from '@amplitude/analytics-react-native';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useAppNavigation } from '_navigator';
import { captureException } from '_utils/Sentry';
import { screenMargin, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { Icon, HeimeText, PrimaryButton, SecondaryButton } from 'Components';
import { PressHere, ImagesBanner, DirectPress } from 'Screens/Home/components/ActivitiesPreview/variants';
import { ArrayElement } from 'types/Utility';
import { suggestions } from '../common/suggestions';

const EmptyActivities = ({ filter }: { filter: 'future' | 'futureOnlyAttending' | 'previous' }) => {
    const { theme } = useThemeContext();
    const { t } = useTranslation();

    return (
        <View
            style={{
                width: '100%',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                gap: screenMargin,
            }}
        >
            <Icon name="calendar" color="main" scale={2} />
            <HeimeText
                style={{
                    fontSize: subtitleFontSize,
                    color: theme.black,
                    textAlign: 'center',
                }}
            >
                {t(`activities:empty_${filter}`)}
            </HeimeText>
            <View
                style={{
                    width: '100%',
                    paddingHorizontal: screenMargin,
                }}
            >
                {filter === 'future' ? <EmptyActivitiesCta /> : null}
            </View>
        </View>
    );
};

const variants = ['press_here', 'images_banner', 'direct_press', 'primary_button'];
const variant = variants[Math.floor(Math.random() * variants.length)];

const EmptyActivitiesCta = () => {
    const { navigate } = useAppNavigation();
    const { t } = useTranslation();

    const goToActivitySuggestions = (suggestion?: ArrayElement<typeof suggestions>) => {
        if (suggestion) {
            track('Pressed activities all suggestions CTA', { variant, suggestion });
            navigate('ActivitySuggestionSelected', { suggestion });
        } else {
            track('Pressed activities all suggestions CTA', { variant });
            navigate('ActivitySuggestions', undefined);
        }
    };

    const handleCreateActivity = () => {
        track('Pressed direct press create activity CTA', { variant: 'direct_press' });
        navigate('ActivityNew', undefined);
    };

    useEffect(() => {
        track('Showing activity suggestions CTA', { variant });
    }, []);

    switch (variant) {
        case 'press_here':
            return <PressHere onPress={() => goToActivitySuggestions()} />;
        case 'images_banner':
            return <ImagesBanner onPress={() => goToActivitySuggestions()} />;
        case 'direct_press':
            return <DirectPress onPress={goToActivitySuggestions} />;
        case 'primary_button':
            return (
                <View
                    style={{
                        gap: screenMargin,
                    }}
                >
                    <PrimaryButton
                        onPress={() => goToActivitySuggestions()}
                        text={t('activities:empty_button_suggestion')}
                    />
                    <SecondaryButton onPress={handleCreateActivity} text={t('activities:empty_button_create')} />
                </View>
            );
    }
    captureException(new Error(`Unknown variant: ${variant}`));
    return null;
};

export default EmptyActivities;
