import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useContextMenuFocusEffect } from '_navigator';
import { Theme, useIsCoopAdmin, useThemeStyle } from '_utils';
import { screenMargin } from '_utils/sizes';
import { HeaderWithNav, Tabs } from 'Components';
import ReportsList from './ReportsList';

const ReportsAll = (): ReactElement => {
    const { t } = useTranslation();
    const themeStyles = useThemeStyle(styles);
    useContextMenuFocusEffect(['reportsNew']);
    const isCoopAdmin = useIsCoopAdmin();

    return (
        <View style={themeStyles.main}>
            <HeaderWithNav title={t('reports:header')} safeArea />
            {isCoopAdmin ? (
                <Tabs
                    style={themeStyles.tabStyle}
                    tabs={[
                        {
                            title: t('reports:open'),
                            content: <ReportsList filter="open" />,
                        },
                        {
                            title: t('reports:yoursAndPublic'),
                            content: <ReportsList filter={null} publicAndOwn />,
                        },
                        {
                            title: t('reports:closed'),
                            content: <ReportsList filter="closed" />,
                        },
                    ]}
                    background="lightBackground"
                />
            ) : (
                <ReportsList filter={null} publicAndOwn />
            )}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.lightBackground,
            height: '100%',
        },
        tabStyle: {
            flex: 1,
        },
        button: { margin: screenMargin, backgroundColor: theme.mainBackground },
    });

export { ReportsAll };
