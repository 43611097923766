import React, { ReactElement } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import _fonts from '../_fonts';
import { WW, useThemeStyle, Theme } from '../_utils';

interface EmptyMessageProps {
    message: string;
}

const EmptyMessage = ({ message }: EmptyMessageProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View style={themedStyle.container}>
            <View style={themedStyle.emptyContainer}>
                <Text style={themedStyle.emptyText}>{message}</Text>
            </View>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            alignItems: 'center',
        },
        emptyContainer: {
            paddingTop: WW * 0.05,
            paddingBottom: WW * 0.05,
            paddingLeft: WW * 0.05,
            paddingRight: WW * 0.05,
            backgroundColor: theme.chatMyMessageBubble,
            borderRadius: 15,
        },
        emptyText: {
            fontFamily: _fonts.primaryFont,
            textAlign: 'center',
        },
    });

export default EmptyMessage;
