import React, { useEffect } from 'react';
import { useAppNavigation } from '_navigator';
import { track } from '_utils/Amplitude';
import { captureException } from '_utils/Sentry';
import { suggestions } from 'Screens/Activities/common/suggestions';
import { ArrayElement } from 'types/Utility';
import { DirectPress, ImagesBanner, PressHere } from './variants';

const variants = ['press_here', 'images_banner', 'direct_press'];
const variant = variants[Math.floor(Math.random() * variants.length)];

const EmptyActivitiesPreview = () => {
    const { navigate } = useAppNavigation();

    const goToActivitySuggestions = (suggestion?: ArrayElement<typeof suggestions>) => {
        if (suggestion) {
            track('Pressed activity suggestions CTA', { variant, suggestion });
            navigate('ActivitySuggestionSelected', { suggestion });
        } else {
            track('Pressed activity suggestions CTA', { variant });
            navigate('ActivitySuggestions', undefined);
        }
    };

    useEffect(() => {
        track('Showing activity suggestions CTA', { variant });
    }, []);

    switch (variant) {
        case 'press_here':
            return <PressHere onPress={() => goToActivitySuggestions()} />;
        case 'images_banner':
            return <ImagesBanner onPress={() => goToActivitySuggestions()} />;
        case 'direct_press':
            return <DirectPress onPress={goToActivitySuggestions} />;
    }
    captureException(new Error(`Unknown variant: ${variant}`));
    return null;
};

export default EmptyActivitiesPreview;
