import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text } from 'react-native';
import { useSettings } from '../../_api';
import { APP_VERSION } from '../../_constants';
import { Theme, isiOS, useThemeStyle, isWeb, openURL } from '../../_utils';
import { captureException } from '../../_utils/Sentry';
import { Modal } from '../../Components';

const UpdateAppAlert = (): ReactElement | null => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data: settings } = useSettings();

    const showModal = useMemo(() => {
        try {
            if (!settings?.app_version || !APP_VERSION) {
                return false;
            }
            const settingNumbers = settings.app_version.split('.').map((curr) => parseInt(curr, 10));
            const appVersionNumber = APP_VERSION.split('.').map((curr) => parseInt(curr, 10));

            for (let index = 0; index < Math.min(settingNumbers.length, appVersionNumber.length); index++) {
                if (settingNumbers[index] > appVersionNumber[index]) {
                    // Setting newer
                    return true;
                } else if (settingNumbers[index] < appVersionNumber[index]) {
                    // Appversion newer
                    return false;
                }
            }
            // Versions are the same
            return false;
        } catch (e: unknown) {
            captureException(e);
            return false;
        }
    }, [settings?.app_version]);

    if (!showModal || isWeb()) {
        return null;
    }

    const handleDismissClick = () => {};
    const handleGoToStore = () => {
        if (isiOS()) {
            openURL('itms-apps://apps.apple.com/id/app/halojasa/id1545319598?l=no', t);
        } else {
            openURL('https://play.google.com/store/apps/details?id=com.heime.app', t);
        }
    };

    const isIos = isiOS();

    return (
        <Modal
            onDismiss={handleDismissClick}
            hideCloseButton
            header={t('updateApp:header')}
            content={
                <Text style={themedStyle.description}>
                    {t('updateApp:description', { store: t(`updateApp:${isIos ? 'ios' : 'android'}`) })}
                </Text>
            }
            buttons={[
                {
                    text: t('updateApp:goToButton', { store: t(`updateApp:${isIos ? 'ios' : 'android'}`) }),
                    onPress: handleGoToStore,
                },
            ]}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        description: {
            fontSize: 14,
            color: theme.secondaryText,
            textAlign: 'center',
            marginBottom: 12,
        },
    });

export default UpdateAppAlert;
