import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { Rect, Svg } from 'react-native-svg';
import Images from '_images';
import { WW, WH } from '_utils';
import { screenMargin, subtitleFontSize, titleFontSize, smallestMargin } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { CacheImage, HeimeText } from 'Components';

const ImagesBanner = ({ onPress }: { onPress(): void }) => {
    const { t } = useTranslation();
    const { theme } = useThemeContext();

    return (
        <TouchableOpacity
            style={{
                borderRadius: 8,
                borderWidth: 1,
                borderColor: theme.lightGrey,
            }}
            onPress={onPress}
        >
            <View
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    overflow: 'hidden',
                }}
            >
                <Svg width="100%" height="100%" viewBox="0 0 100 100" fill="none">
                    <Rect x="95" y="-40" width="70" height="70" rx={50} fill={theme.lightGreen} />
                    <Rect x="100" y="50" width="100" height="100" rx={50} fill={theme.lightGreen} />
                </Svg>
                <CacheImage
                    source={Images.walk}
                    style={{
                        width: WW * 0.2,
                        height: WW * 0.2,
                        position: 'absolute',
                        borderRadius: WH * 0.5,
                        right: '7%',
                        top: '25%',
                    }}
                />
                <CacheImage
                    source={Images.coffee}
                    style={{
                        width: WW * 0.13,
                        height: WW * 0.13,
                        position: 'absolute',
                        borderRadius: WH * 0.5,
                        top: '-9%',
                        right: '22%',
                    }}
                />
                <CacheImage
                    source={Images.party}
                    style={{
                        width: WW * 0.13,
                        height: WW * 0.13,
                        position: 'absolute',
                        borderRadius: WH * 0.5,
                        bottom: '-3%',
                        right: '-2%',
                    }}
                />
                <CacheImage
                    source={Images.bbq}
                    style={{
                        width: WW * 0.1,
                        height: WW * 0.1,
                        position: 'absolute',
                        borderRadius: WH * 0.5,

                        top: '-1%',
                        right: '-1%',
                    }}
                />
                <CacheImage
                    source={Images.knitting}
                    style={{
                        position: 'absolute',
                        borderRadius: WH * 0.5,
                        width: WW * 0.1,
                        height: WW * 0.1,

                        bottom: '5%',
                        right: '25%',
                    }}
                />
            </View>
            <View
                style={{
                    padding: screenMargin,
                    gap: screenMargin,
                }}
            >
                <HeimeText style={{ color: theme.main, fontSize: subtitleFontSize }}>
                    {t('activity:suggestions:images_banner_top')}
                </HeimeText>
                <HeimeText style={{ color: theme.main, fontSize: titleFontSize, fontWeight: 'bold', maxWidth: '70%' }}>
                    {t('activity:suggestions:images_banner_title')}
                </HeimeText>
                <View
                    style={{
                        width: '30%',
                        alignItems: 'center',
                        backgroundColor: theme.main,
                        borderRadius: 4,
                        paddingVertical: smallestMargin,
                    }}
                >
                    <HeimeText style={{ color: theme.white }}>{t('activity:suggestions:images_banner_cta')}</HeimeText>
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default ImagesBanner;
