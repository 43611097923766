import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Images from '_images';
import { useAppNavigation } from '_navigator';
import { track } from '_utils/Amplitude';
import { screenMargin, smallestFontSize, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { CacheImage, HeaderWithNav, HeimeText } from 'Components';
import { ActivitySuggestionItem } from '../common';
import { suggestions } from '../common/suggestions';

const variant = Math.random() < 0.7 ? 'image' : 'no_image';

const ActivitySuggestions = (): ReactElement => {
    const { t } = useTranslation();
    const { theme } = useThemeContext();
    const insets = useSafeAreaInsets();
    const { navigate } = useAppNavigation();

    useEffect(() => {
        track('Showing activity suggestions', { variant });
    }, []);

    const options = useMemo(
        () =>
            suggestions.map((suggestion) => ({
                key: suggestion,
                title: t(`activity:suggestions:${suggestion}_title`),
                description: t(`activity:suggestions:${suggestion}_description`),
                image: Images[suggestion],
            })),
        [t],
    );

    return (
        <View style={{ height: '100%', backgroundColor: theme.mainBackground }}>
            <HeaderWithNav title={t('activity:suggestions:header')} />
            <ScrollView
                style={{ height: '100%', flex: 1 }}
                contentContainerStyle={{
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    paddingHorizontal: screenMargin,
                    gap: smallestMargin,
                    paddingBottom: insets.bottom,
                }}
            >
                <HeimeText style={{ flexGrow: 1, marginBottom: smallestMargin }} variant="title">
                    {t('activity:suggestions:subtitle')}
                </HeimeText>
                {options.map((item) =>
                    variant === 'image' ? (
                        <TouchableOpacity
                            key={item.key}
                            onPress={() => navigate('ActivitySuggestionSelected', { suggestion: item.key })}
                            style={{
                                borderRadius: 12,
                                paddingBottom: screenMargin,
                                gap: smallestMargin,
                                alignItems: 'center',
                                borderWidth: 1,
                                borderColor: theme.mediumGrey,
                                width: '48%',
                                overflow: 'hidden',
                            }}
                        >
                            <CacheImage
                                source={item.image}
                                style={{
                                    width: '100%',
                                    height: 80,
                                    borderColor: theme.main,
                                }}
                                resizeMode={item.key === 'other' ? 'contain' : 'cover'}
                            />
                            <HeimeText
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: subtitleFontSize,
                                    textAlign: 'center',
                                    paddingHorizontal: smallestMargin,
                                }}
                            >
                                {item.title}
                            </HeimeText>
                            <HeimeText
                                style={{
                                    fontSize: smallestFontSize,
                                    textAlign: 'left',
                                    paddingHorizontal: smallestMargin,
                                }}
                            >
                                {item.description}
                            </HeimeText>
                        </TouchableOpacity>
                    ) : (
                        <ActivitySuggestionItem
                            key={item.key}
                            suggestion={item.key}
                            onPress={() => navigate('ActivitySuggestionSelected', { suggestion: item.key })}
                        />
                    ),
                )}
            </ScrollView>
        </View>
    );
};

export default ActivitySuggestions;
