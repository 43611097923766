import React, { ComponentProps, ReactElement } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import HeimeText from './HeimeText';
import Icon from './Icon/Icon';
import { useThemeStyle, WW } from '../_utils';
import { screenMargin } from '../_utils/sizes';
import { Theme } from '../_utils/themeContext';

interface IconButtonProps {
    onPress(): void;
    icon: ComponentProps<typeof Icon>['name'];
    text?: string;
    accessibilityHint?: string;
}

const IconButton = ({ onPress, icon, text, accessibilityHint }: IconButtonProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);

    if (!text && !accessibilityHint) {
        console.warn('IconButton: No text or accessibilityHint provided');
    }

    return (
        <TouchableOpacity accessibilityHint={accessibilityHint} onPress={onPress} style={themedStyle.button}>
            <Icon name={icon} color="black" />
            {text ? <HeimeText style={themedStyle.text}>{text}</HeimeText> : null}
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        button: {
            alignSelf: 'flex-end',
            borderRadius: 10,
            paddingTop: WW * 0.03,
            paddingBottom: WW * 0.03,
            paddingRight: WW * 0.03,
            paddingLeft: WW * 0.03,
            backgroundColor: theme.mainBackground,
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            borderColor: theme.greyMedium,
            borderWidth: 1,
            shadowColor: theme.black,
            shadowOpacity: 0.1,
            shadowOffset: {
                width: 2,
                height: 2,
            },
            shadowRadius: 4,
        },
        text: { marginLeft: screenMargin / 2 },
    });

export default IconButton;
