import React, { ReactElement } from 'react';
import { View, StyleSheet, Text, ViewStyle, TouchableWithoutFeedback, StyleProp, TextStyle } from 'react-native';
import { Icon } from '.';
import _fonts from '../_fonts';
import { WW } from '../_utils';

interface CheckBoxProps {
    style?: ViewStyle;
    titleStyle?: StyleProp<TextStyle>;
    onPress(): void;
    checked: boolean;
    title?: string;
}

const CheckBox = ({ style, onPress, checked, title, titleStyle }: CheckBoxProps): ReactElement => {
    return (
        <TouchableWithoutFeedback
            role="checkbox"
            accessibilityState={{ checked }}
            hitSlop={{ top: 10, bottom: 20 }}
            onPress={onPress}
        >
            <View style={[styles.container, style]}>
                <Icon name={checked ? 'checked' : 'unchecked'} color="black" />
                <Text maxFontSizeMultiplier={2} style={[styles.title, titleStyle]}>
                    {title}
                </Text>
            </View>
        </TouchableWithoutFeedback>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 0,
        flexWrap: 'nowrap',
        minHeight: 40,
    },
    title: {
        marginLeft: WW * 0.02,
        fontFamily: _fonts.primaryFont,
        fontSize: WW * 0.03,
    },
});

export default CheckBox;
