import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

interface SettingsDto {
    phone: string;
    mail: string;
    app_version: string;
    current_time: string;
}

const getSettings = async (): Promise<SettingsDto> => {
    const response = await axios.get('settings');
    return response.data;
};

const useSettings = (): UseQueryResult<SettingsDto, string | Error | AxiosError> => {
    const placeholderData = useStaticSettings();
    return useQuery({
        queryKey: ['settings'],
        queryFn: getSettings,
        gcTime: Infinity,
        staleTime: 1000 * 60 * 60 * 24,
        placeholderData,
    });
};

const useRemoveSettings = (): (() => void) => {
    const queryClient = useQueryClient();
    return () => {
        queryClient.removeQueries({
            queryKey: ['settings'],
        });
    };
};

const useStaticSettings = (): SettingsDto => ({
    phone: '+47 51 73 27 70',
    mail: 'vetle@heime.com',
    app_version: '',
    current_time: '',
});

export { useSettings, useStaticSettings, useRemoveSettings };
