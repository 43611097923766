import { useAnimatedKeyboard, useAnimatedStyle } from 'react-native-reanimated';
import { isAndroid } from '_utils/isPlatform';

const useTranslatedKeyboardHeight = isAndroid()
    ? () => ({})
    : () => {
          const { height: keyboardHeight } = useAnimatedKeyboard();
          return useAnimatedStyle(() => {
              return {
                  transform: [{ translateY: keyboardHeight.value }],
              };
          }, [keyboardHeight]);
      };

export default useTranslatedKeyboardHeight;
