import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { Modal, HeimeText } from '../../Components';

const useConfirmDiscard = (
    blockNavigation: boolean,
    isEditing: boolean,
    onConfirm: () => void,
): { goBack(): void; content: ReactElement | null; openModal(): void } => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigation = useNavigation();

    const openModal = useCallback(() => setIsModalOpen(true), []);
    useEffect(() => {
        const unsubscribe = navigation.addListener('beforeRemove', (e) => {
            if (!blockNavigation || isModalOpen) {
                // If we don't have unsaved changes, or modal already open we should not interfere
                // Note this makes double back press exit the screen
                return;
            }

            // Prevent default behavior of leaving the screen
            e.preventDefault();
            openModal();
        });
        return () => unsubscribe();
    }, [navigation, blockNavigation, openModal, isModalOpen]);

    const handleConfirm = () => {
        onConfirm();
        setIsModalOpen(false);
    };

    return {
        goBack: blockNavigation ? openModal : onConfirm,
        content: isModalOpen ? (
            <CancelChangesModal
                isEditing={isEditing}
                onDismiss={() => setIsModalOpen(false)}
                onCancel={handleConfirm}
            />
        ) : null,
        openModal,
    };
};

interface CancelChangesModal {
    onDismiss(): void;
    onCancel(): void;
    isEditing: boolean;
}

const CancelChangesModal = ({ onDismiss, onCancel, isEditing }: CancelChangesModal): ReactElement => {
    const { t } = useTranslation();
    return (
        <Modal
            onDismiss={onDismiss}
            header={isEditing ? t('global:cancelModal:cancelChanges') : t('global:cancelModal:cancelCreation')}
            content={
                <HeimeText style={Styles.content}>
                    {isEditing ? t('global:cancelModal:contentEdited') : t('global:cancelModal:content')}
                </HeimeText>
            }
            buttons={[
                { text: t('global:cancelModal:back'), onPress: onDismiss },
                {
                    text: t('global:cancelModal:confirmCancel'),
                    onPress: onCancel,
                    type: 'danger',
                },
            ]}
        />
    );
};

const Styles = StyleSheet.create({ content: { textAlign: 'center' } });

export default useConfirmDiscard;
