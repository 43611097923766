export const suggestions = [
    'coffee',
    'christmas_lighting',
    'bbq',
    'outdoor_cinema',
    'sport',
    'common_dinner',
    'knitting',
    'walk',
    'bathing',
    'party',
    'community_effort',
    'workout',
    'boardgames',
    'other',
] as const;
