import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../ActivityNew';

type TimeSetting = { defaultValue: Date; minDate: Date; name: keyof FormValues };
const useTimeSettings = (): { start: TimeSetting; end: TimeSetting } => {
    const { watch } = useFormContext<FormValues>();
    const start = watch('start_at');
    const end = watch('end_at');

    const defaultStart = moment().add({ hour: 1 }).startOf('hour').toDate();
    const defaultEnd = moment(start ? start : defaultStart)
        .add({ minutes: 30 })
        .endOf('minute')
        .toDate();

    const initialMinStart = new Date();
    const initialMinEnd = moment(start);

    return {
        start: {
            defaultValue: defaultStart,
            minDate: start ? moment.min(moment(start), moment(initialMinStart)).toDate() : initialMinStart,
            name: 'start_at',
        },
        end: {
            defaultValue: defaultEnd,
            minDate: end ? moment.min(moment(end), initialMinEnd).toDate() : initialMinEnd.toDate(),
            name: 'end_at',
        },
    };
};

export default useTimeSettings;
