import React, { ComponentProps, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useSelectedCoopItem } from '_utils/hooks';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { Apartment } from 'types/Apartment';
import _fonts from '../../../../../_fonts';
import { useApartmentColumnOrder, useThemeStyle, WW } from '../../../../../_utils';
import { Theme } from '../../../../../_utils/themeContext';
import { HeimeText, Icon, SelectorChip } from '../../../../../Components';

interface ApartmentInfoProps {
    apartment: Apartment;
}

const ApartmentInfo = ({ apartment }: ApartmentInfoProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    const order = useApartmentColumnOrder(useSelectedCoopItem()?.apartment_display ?? 'project_name');

    return (
        <View style={themedStyle.main}>
            <ApartmentInfoItem
                icon="location"
                items={order
                    .filter((key) => apartment[key])
                    .map((key) => ({ title: t(`myHome:${key}`), value: apartment[key] }))}
            />
            {apartment.type ? (
                <ApartmentInfoItem
                    icon="home"
                    items={[
                        {
                            title: t('myHome:type'),
                            value: t(`myHome:${apartment.type ?? 'apartment'}`),
                        },
                    ]}
                />
            ) : undefined}

            {apartment.floor ? (
                <ApartmentInfoItem
                    icon="floor"
                    items={[
                        {
                            title: t('myHome:floor'),
                            value: `${apartment.floor}`,
                        },
                    ]}
                />
            ) : undefined}

            {apartment.area ? (
                <ApartmentInfoItem
                    icon="area"
                    items={[
                        {
                            title: t('myHome:area'),
                            value: t('myHome:area_value', { area: apartment.area }),
                        },
                    ]}
                />
            ) : undefined}

            {apartment.living_area ? (
                <ApartmentInfoItem
                    icon="areaUsable"
                    items={[
                        {
                            title: t('myHome:living_area'),
                            value: t('myHome:area_value', { area: apartment.living_area }),
                        },
                    ]}
                />
            ) : undefined}

            {apartment.rooms ? (
                <ApartmentInfoItem
                    icon="bedroom"
                    items={[
                        {
                            title: t('myHome:bedrooms'),
                            value: `${apartment.rooms}`,
                        },
                    ]}
                />
            ) : undefined}
            {apartment.parking_spot ? (
                <ApartmentInfoItem
                    icon="pin2"
                    items={[
                        {
                            title: t('myHome:parking_spot'),
                            value: apartment.parking_spot,
                        },
                    ]}
                />
            ) : undefined}
            {apartment.apartment_groups.length > 0 ? (
                <View style={[themedStyle.content, themedStyle.groupContainer]}>
                    <HeimeText style={[themedStyle.title, themedStyle.groupTitle]}>
                        {t('myHome:apartment_groups')}
                    </HeimeText>
                    <View style={themedStyle.groupItemsContainer}>
                        {apartment.apartment_groups.map((value) => (
                            <SelectorChip
                                variant="lightPrimary"
                                title={value.name}
                                key={value.id}
                                containerStyle={themedStyle.groupChip}
                            />
                        ))}
                    </View>
                </View>
            ) : undefined}
        </View>
    );
};

interface ApartmentInfoItemProp {
    icon: ComponentProps<typeof Icon>['name'];
    items: Array<{
        title: string;
        value: string;
    }>;
}

const ApartmentInfoItem = ({ icon, items }: ApartmentInfoItemProp) => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View style={themedStyle.content}>
            <Icon name={icon} color="black" />
            <View style={{ gap: 14, flex: 1 }}>
                {items.map(({ title, value }, id) => (
                    <View key={id} style={{ flexDirection: 'row', gap: 12, flex: 1 }}>
                        <HeimeText maxFontSizeMultiplier={2} style={themedStyle.title}>
                            {title}
                        </HeimeText>
                        <HeimeText maxFontSizeMultiplier={2} style={themedStyle.subTitle}>
                            {value}
                        </HeimeText>
                    </View>
                ))}
            </View>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
        },
        content: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginRight: screenMargin,
            marginLeft: screenMargin,
            paddingTop: 14,
            paddingBottom: 14,
            borderBottomColor: theme.lightGrey,
            borderBottomWidth: 1,
        },
        title: {
            paddingLeft: WW * 0.01,
            paddingRight: WW * 0.01,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.04,
            color: theme.black,
        },
        subTitle: {
            flex: 1,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            color: theme.secondaryText,
        },
        groupContainer: {
            flexDirection: 'column',
            gap: smallestMargin,
        },
        groupChip: {
            flexGrow: 0,
        },
        groupTitle: {
            fontSize: subtitleFontSize,
        },
        groupItemsContainer: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', rowGap: smallestMargin },
    });

export default ApartmentInfo;
