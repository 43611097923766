import React, { ReactElement, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { LaunchArguments } from '_dependencies/launchArguments';
import Modal from '_dependencies/rnModal';
import Images from '_images';
import { track } from '_utils/Amplitude';
import { screenMargin, smallestMargin, subtitleFontSize, titleFontSize } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import CacheImage from 'Components/CacheImage';
import { useOwnProfile } from '../../_api/useProfile';
import { useAppNavigation } from '../../_navigator';
import { dismissAddProfile } from '../../_redux/addProfileModal';
import { Theme, useAppDispatch, useGetExampleNeighbors, useGlobalState, useThemeStyle, WW } from '../../_utils';
import { CheckBox, CloseButton, HeimeText, PrimaryButton, SecondaryButton } from '../../Components';

const AddProfileModal = (): ReactElement | null => {
    const { data: ownProfile, isLoading: loadingOwnProfile } = useOwnProfile();
    const { showAfter } = useGlobalState((state) => state.addProfileModal);

    const shouldShow =
        LaunchArguments.value<{ disableAddProfileModal: boolean }>().disableAddProfileModal !== true &&
        // Profile loaded and user is onboarded
        !loadingOwnProfile &&
        ownProfile?.gdpr &&
        (!showAfter || moment.unix(showAfter).isBefore(moment())) &&
        (!ownProfile?.avatar || !ownProfile.about_me);

    if (shouldShow) {
        const reason = !ownProfile?.avatar && !ownProfile?.about_me ? 'both' : ownProfile?.avatar ? 'about' : 'avatar';
        return <ActualModal reason={reason} />;
    }
    return null;
};

const ActualModal = ({ reason }: { reason: 'both' | 'about' | 'avatar' }) => {
    const { theme } = useContext(ThemeContext);
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [neverAsk, setIsNeverAsk] = useState(false);
    const { navigate } = useAppNavigation();

    const users = useGetExampleNeighbors(reason === 'avatar' ? 'avatar' : 'either');
    useEffect(() => {
        track('Add profile modal shown', { reason });
    }, [reason]);

    const toggleRemember = () => {
        setIsNeverAsk(!neverAsk);
    };

    const handleDismiss = (dontAskAgain = neverAsk, noTrack = false) => {
        if (!noTrack) {
            track('Add profile modal dismissed', { reason, dontAskAgain });
        }
        if (dontAskAgain) {
            // Dont ask for two years
            dispatch(dismissAddProfile(moment().add({ years: 2 }).unix()));
        } else {
            // Ask again in a week
            dispatch(dismissAddProfile(moment().add({ weeks: 1 }).unix()));
        }
    };

    const handleDo = () => {
        handleDismiss(false, true);
        track("Add profile modal 'continue' clicked", { reason });
        navigate(reason === 'about' ? 'ProfileAboutMe' : 'ProfileEdit', undefined);
    };

    const getHandleSelectUser = (id: number) => () => {
        dispatch(dismissAddProfile(moment().add({ minutes: 5 }).unix()));
        navigate('Profile', { id });
    };

    const texts =
        reason === 'both'
            ? {
                  namesDescription: t('profile:updateInfoModal:namesDescription'),
                  title: t('profile:updateInfoModal:title'),
                  description: t('profile:updateInfoModal:description'),
              }
            : reason === 'about'
              ? {
                    namesDescription: t('profile:updateInfoModal:namesDescription_no_about'),
                    title: t('profile:updateInfoModal:title'),
                    description: t('profile:updateInfoModal:description_no_about'),
                }
              : {
                    namesDescription: t('profile:updateInfoModal:namesDescription_no_avatar'),
                    title: t('profile:updateInfoModal:title_no_avatar'),
                    description: t('profile:updateInfoModal:description_no_avatar'),
                };

    const numbersCase = users.length < 2 ? 'few' : users.length === 3 ? 'three' : 'many';

    return (
        <Modal
            onDismiss={handleDismiss}
            onBackButtonPress={handleDismiss}
            onBackdropPress={handleDismiss}
            isVisible
            style={themedStyle.modal}
            avoidKeyboard
            backdropColor={theme.black}
        >
            <View style={themedStyle.modalContent}>
                <View style={themedStyle.topContainer}>
                    <CloseButton style={themedStyle.closeButton} onPress={handleDismiss} />
                    <View style={themedStyle.imageContainer}>
                        {[...users].splice(0, numbersCase === 'three' ? 3 : 2).map((item, index) => (
                            <TouchableOpacity
                                onPress={getHandleSelectUser(item.id)}
                                style={[themedStyle.imageItem, { zIndex: 10 - index }]}
                                key={item.id}
                            >
                                <CacheImage
                                    source={item.avatar ?? Images.defaultAvatar}
                                    style={themedStyle.image}
                                    key={item.id}
                                />
                            </TouchableOpacity>
                        ))}
                        {numbersCase === 'many' ? (
                            <View style={themedStyle.imageItem}>
                                <CacheImage
                                    source={users[2]?.avatar ?? Images.defaultAvatar}
                                    style={themedStyle.image}
                                />
                                <View style={themedStyle.moreItem} />
                                <HeimeText style={themedStyle.moreText}>+ {users.length - 2}</HeimeText>
                            </View>
                        ) : null}
                    </View>
                    <HeimeText style={themedStyle.names}>
                        {[...users]
                            .splice(0, 3)
                            .map((item) => item.fname)
                            .join(', ')}
                        {numbersCase === 'many' ? (
                            <HeimeText style={themedStyle.namesEmphasis}>
                                {t('profile:updateInfoModal:and_more')}
                            </HeimeText>
                        ) : null}
                    </HeimeText>
                    <HeimeText style={themedStyle.namesDescription}>{texts.namesDescription}</HeimeText>
                </View>
                <View style={themedStyle.bottom}>
                    <HeimeText style={themedStyle.title}>{texts.title}</HeimeText>
                    <HeimeText style={themedStyle.description}>{texts.description}</HeimeText>
                    <PrimaryButton text={t('profile:updateInfoModal:continue')} onPress={handleDo} />
                    <SecondaryButton text={t('profile:updateInfoModal:back')} onPress={handleDismiss} />
                    <CheckBox
                        title={t('profile:updateInfoModal:checkbox')}
                        onPress={toggleRemember}
                        checked={neverAsk}
                    />
                </View>
            </View>
        </Modal>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContent: {
            backgroundColor: theme.mainBackground,
            position: 'relative',
        },
        topContainer: {
            backgroundColor: theme.lightGreen,
            padding: screenMargin,
            paddingBottom: screenMargin * 2,
        },
        imageContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: screenMargin,
        },
        imageItem: {
            width: WW * 0.2,
            alignItems: 'center',
            justifyContent: 'center',
        },
        image: {
            width: WW * 0.25,
            height: WW * 0.25,
            borderRadius: 100,
            borderWidth: 5,
            borderColor: theme.white,
            overflow: 'hidden',
        },
        moreItem: {
            backgroundColor: theme.white,
            position: 'absolute',
            bottom: 'auto',
            right: 'auto',
            opacity: 0.8,
            width: WW * 0.25,
            borderRadius: 100,
            height: '100%',
            zIndex: 1,
        },
        moreText: {
            fontWeight: 'bold',
            fontSize: titleFontSize,
            position: 'absolute',
            bottom: 'auto',
            right: 'auto',
            zIndex: 1,
        },
        closeButton: {
            marginLeft: 'auto',
            marginRight: -10,
        },
        names: {
            fontSize: subtitleFontSize,
            color: theme.main,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        namesEmphasis: {
            fontWeight: 'normal',
        },
        namesDescription: {
            fontSize: subtitleFontSize,
            color: theme.greyMedium,
            textAlign: 'center',
        },
        bottom: {
            flexDirection: 'column',
            gap: smallestMargin,
            padding: screenMargin,
        },
        title: {
            fontSize: titleFontSize,
            color: theme.main,
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: smallestMargin,
        },
        description: {
            fontSize: subtitleFontSize,
            color: theme.greyMedium,
            textAlign: 'center',
            marginBottom: screenMargin,
        },
    });

export default AddProfileModal;
