import { useEffect, useMemo } from 'react';
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { safeParse } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import { Category, Product, CategorySchema, ProductSchema } from '../types/Category';

const categoryResponseSchema = z.object({
    categories: z.array(CategorySchema),
    products: z.array(ProductSchema),
});

type CategoryResponse = z.infer<typeof categoryResponseSchema>;

const getCategories = async (getAuthHeader: () => Promise<string>, selectedCoopId: number) => {
    const authHeader = await getAuthHeader();
    const result = await axios.get<unknown>(`cooperatives/${selectedCoopId}/categories`, {
        headers: { authorization: authHeader },
    });

    return result;
};

const getCategoriesKey = (selectedCoop: number) => ['categories', selectedCoop];

const usePrefetchCategories = (selectedCoopId: number): void => {
    const queryClient = useQueryClient();
    const getAUthHeader = useGetAccessTokenHeader();

    useEffect(() => {
        const data = queryClient.getQueryData(getCategoriesKey(selectedCoopId));
        if (!data) {
            queryClient.prefetchQuery({
                queryKey: getCategoriesKey(selectedCoopId),
                queryFn: async () => {
                    const result = await getCategories(getAUthHeader, selectedCoopId);
                    return safeParse(result.data, categoryResponseSchema);
                },
            });
        }
    }, [getAUthHeader, queryClient, selectedCoopId]);
};

const useGetCategories = (): UseQueryResult<CategoryResponse, string | Error> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    return useQuery({
        queryKey: getCategoriesKey(selectedCoopId),

        queryFn: async () => {
            const result = await getCategories(getAuthHeader, selectedCoopId);
            if (result.data) {
                return safeParse(result.data, categoryResponseSchema);
            }
            throw new Error('Result returned with no data');
        },
        gcTime: Infinity,
        staleTime: 1000 * 60 * 60,
    });
};

const getReduceCategory = (selectedCategoryId: number) => (acc: Category, cur: Category) => {
    if (acc) {
        return acc;
    }
    if (cur.id === selectedCategoryId) {
        return cur;
    }
    return cur.categories.reduce(getReduceCategory(selectedCategoryId));
};

/**
 * @param productId product
 * @param categoryId if provided we might use category cache to retrieve the details.
 * @returns Product details
 */
const useGetProduct = (productId: number, categoryId?: number): UseQueryResult<Product, string | Error> => {
    const selectedCoopId = useSelectedCoop();

    const queryClient = useQueryClient();
    const initialData = useMemo(() => {
        const query = queryClient.getQueryState<unknown>(getCategoriesKey(selectedCoopId));
        if (query?.data) {
            let data = safeParse(query.data, categoryResponseSchema);
            if (categoryId) {
                const found = data.categories.reduce(getReduceCategory(categoryId))?.products;
                const product = found?.find((p) => p.id === productId);
                if (product) {
                    return { initialData: product, dataUpdatedAt: query?.dataUpdatedAt };
                }
            }

            const func = (acc: Product | undefined, cur: Category): Product | undefined => {
                if (acc) {
                    return acc;
                }
                const found = cur.products.find((p) => p.id === productId);
                if (found) {
                    return found;
                }
                return cur.categories.reduce(func, undefined);
            };
            const product = data.products.find((p) => p.id === productId) ?? data.categories.reduce(func, undefined);
            if (product) {
                return { initialData: product, dataUpdatedAt: query?.dataUpdatedAt };
            }
        }

        return { initialData: undefined, dataUpdatedAt: undefined };
    }, [categoryId, productId, queryClient, selectedCoopId]);

    const getAuthHeader = useGetAccessTokenHeader();
    return useQuery({
        queryKey: ['product', selectedCoopId, productId],

        queryFn: async () => {
            const authHeader = await getAuthHeader();
            const result = await axios.get<unknown>(`cooperatives/${selectedCoopId}/products/${productId}`, {
                headers: { authorization: authHeader },
            });
            if (!result.data) {
                throw new Error('Result returned with success == false');
            }
            return safeParse(result.data, ProductSchema);
        },

        gcTime: Infinity,
        staleTime: 1000 * 60 * 60 * 5,
        enabled: Boolean(productId),
        initialData: initialData.initialData,
        initialDataUpdatedAt: initialData.dataUpdatedAt,
    });
};

export { useGetCategories, usePrefetchCategories, useGetProduct };
