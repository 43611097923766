import { z } from 'zod';
import { ApiImageSchema } from './Base';
import { LaravelBooleanSchema } from './Utility';

export const CooperativeSchema = z.object({
    id: z.number(),
    name: z.string(),
    type: z.union([z.literal('joint_ownership'), z.literal('rental_project'), z.literal('suburban_housing')]),
    payment_accounts: z.array(
        z.object({
            id: z.string(),
            pays_MVA: LaravelBooleanSchema,
            name: z.string(),
            organization_name: z.string().nullable(),
            address: z.string().nullable(),
            postcode: z.string().nullable(),
            city: z.string().nullable(),
            organization_number: z.string().nullable(),
        }),
    ),
    default_apartment_image: ApiImageSchema.nullable(),
    enable_bookkeeping: LaravelBooleanSchema,
    activity_default_account: z.string(),
    cooperative_groups: z.array(
        z.union([
            z.object({ id: z.number(), name: z.string(), type: z.literal('coopGroup') }),
            z.object({
                type: z.literal('cooperative'),
            }),
        ]),
    ),
    white_label: z
        .union([
            z.literal('bakkekontakt'),
            z.literal('madlavest'),
            z.literal('leangen'),
            z.literal('vervet'),
            z.literal('fyrstikkbakken'),
            z.literal('heime'),
            z.literal(''),
        ])
        .nullable(),
    white_label_logo: ApiImageSchema.nullable(),
    white_label_activities: ApiImageSchema.nullable(),
    white_label_services: ApiImageSchema.nullable(),
    white_label_neighborsharing: ApiImageSchema.nullable(),
    white_label_notices: ApiImageSchema.nullable(),
    white_label_theme: z
        .union([
            z.object({
                mainBackground: z.string().optional(),
                accent: z.string().optional(),
                main: z.string().optional(),
                mainText: z.string().optional(),
                secondary: z.string().optional(),
                secondaryLight: z.string().optional(),
                shadow: z.string().optional(),
                darkerShadow: z.string().optional(),
                secondaryBackground: z.string().optional(),
                lightBackground: z.string().optional(),
                dangerBackground: z.string().optional(),
                mediumBackground: z.string().optional(),
                darkGray: z.string().optional(),
                black: z.string().optional(),
                alert: z.string().optional(),
                lightRed: z.string().optional(),
                transparent_red: z.string().optional(),
                red: z.string().optional(),
                hyperText: z.string().optional(),
                error: z.string().optional(),
                errorLight: z.string().optional(),
                chatMyMessageBubble: z.string().optional(),
                chatOtherMessageBubble: z.string().optional(),
                chatMessageText: z.string().optional(),
                secondaryText: z.string().optional(),
                darkGreen: z.string().optional(),
                lightGreen: z.string().optional(),
                lightMustard: z.string().optional(),
                mediumGrey: z.string().optional(),
                success: z.string().optional(),
                darkGrey: z.string().optional(),
                white: z.string().optional(),
                lightGrey: z.string().optional(),
                graphite: z.string().optional(),
                greyMedium: z.string().optional(),
                transparentBlack: z.string().optional(),
                lightYellow: z.string().optional(),
                yellow: z.string().optional(),
                lightGreen1: z.string().optional(),
                green: z.string().optional(),
                ownChatMessageText: z.string().optional(),
                myChatMessageBubble: z.string().optional(),
                otherChatMessageText: z.string().optional(),
                shadowText: z.string().optional(),
            }),
            z.array(z.never()).max(0),
        ])
        .nullable(),
});

export const findInitialCooperativeGroupId = (groupOptions: Cooperative['cooperative_groups']) => {
    const item = groupOptions[0];
    return item?.type === 'coopGroup' ? item.id : null;
};

export type Cooperative = z.infer<typeof CooperativeSchema> & {
    apartment_display: 'project_name' | 'address' | 'legal_name';
};

export type ThemeData = Exclude<Cooperative['white_label_theme'], null>;
