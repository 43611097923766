import React, { ReactElement, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, ScrollView } from 'react-native';
import { useAppNavigation } from '_navigator';
import { Theme, useThemeStyle } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize, subtitleLineHeight, titleFontSize } from '_utils/sizes';
import { HeimeText, PrimaryButton, SecondaryButton } from 'Components';
import { useProcedureContext } from '../ProcedureContext';
import { useProcedureNavigation } from '../ProcedureNavigation';

const StartProcedure = (): ReactElement => {
    const { openProcedure, context } = useProcedureContext();
    const { t } = useTranslation();

    //const { t } = useTranslation();
    const themedStyle = useThemeStyle(styles);
    const { goBack } = useAppNavigation();
    const { navigate } = useProcedureNavigation();

    useFocusEffect(
        useCallback(() => {
            openProcedure(goBack);
        }, [goBack, openProcedure]),
    );

    const handleStart = () => {
        navigate('ProcedureAction', { actionIndex: 0, reservationId: 1 + '' });
    };

    const isCheckin = context.procedure.type === 'check_in';

    return (
        <View style={themedStyle.main}>
            <ScrollView bounces={false} style={{ flex: 1 }}>
                <HeimeText maxFontSizeMultiplier={2} style={themedStyle.itemTitle}>
                    {t(isCheckin ? 'procedure:start_check_in_title' : 'procedure:start_check_out_title', {
                        name: context.productName,
                    })}
                </HeimeText>
                <HeimeText maxFontSizeMultiplier={2} style={themedStyle.itemDescription}>
                    {t('procedure:start_procedure_explainer', { steps: context.procedure.actions.length })}
                </HeimeText>
                {!isCheckin ? (
                    <HeimeText maxFontSizeMultiplier={2} style={themedStyle.itemDescription}>
                        {t('procedure:start_procedure_check_out_explainer')}
                    </HeimeText>
                ) : null}
            </ScrollView>
            <View style={themedStyle.buttonContainer}>
                <PrimaryButton
                    text={isCheckin ? t('procedure:start_check_in_action') : t('procedure:start_check_out_action')}
                    onPress={handleStart}
                />
                <SecondaryButton text={t('procedure:later')} onPress={goBack} />
            </View>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.lightBackground,
            width: '100%',
            flex: 1,
            display: 'flex',
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            paddingBottom: screenMargin,
        },
        itemTitle: {
            fontSize: titleFontSize,
            color: theme.main,
            fontWeight: 'bold',
            marginBottom: smallestMargin,
        },
        itemDescription: {
            fontSize: subtitleFontSize,
            lineHeight: subtitleLineHeight,
            color: theme.black,
            marginBottom: smallestMargin,
        },
        buttonContainer: {
            justifyContent: 'flex-end',
            marginBottom: screenMargin,
            gap: smallestMargin,
        },
    });

export default StartProcedure;
