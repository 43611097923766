import { View } from 'react-native';
import { useAppNavigation } from '_navigator';
import { track } from '_utils/Amplitude';
import { screenMargin } from '_utils/sizes';
import { ActivitySuggestionItem } from 'Screens/Activities/common';
import { suggestions } from 'Screens/Activities/common/suggestions';
import { ArrayElement } from 'types/Utility';

// Take out 4 random suggestions from the suggestions array, and then make sure variant "other" is last in the list if included
const selectedSuggestions = [...suggestions]
    .sort(() => Math.random() - 0.5)
    .slice(0, 4)
    .sort((a, b) => (a === 'other' ? 1 : b === 'other' ? -1 : 0));

const DirectPress = ({ onPress }: { onPress(suggestion: ArrayElement<typeof suggestions>): void }) => {
    const { navigate } = useAppNavigation();

    const handleCreateActivity = () => {
        track('Pressed direct press create activity CTA', { variant: 'direct_press' });
        navigate('ActivityNew', undefined);
    };

    return (
        <View style={{ gap: screenMargin }}>
            {selectedSuggestions.map((suggestion) => (
                <ActivitySuggestionItem key={suggestion} onPress={() => onPress(suggestion)} suggestion={suggestion} />
            ))}
            <ActivitySuggestionItem onPress={handleCreateActivity} />
        </View>
    );
};

export default DirectPress;
