import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import moment from 'moment';
import { FormProvider, Path, useForm } from 'react-hook-form';
import { BackHandler, StyleSheet, View } from 'react-native';
import * as z from 'zod';
import { Activity } from '_api/useActivities';
import { optionalNumberInput } from '_utils/zod';
import { Cooperative, findInitialCooperativeGroupId } from 'types/Cooperative';
import {
    ActivityAbout,
    ActivityDateTime,
    ActivityLocation,
    ActivityPhoto,
    ActivityCost,
    ActivityAlert,
    ActivityAddDetailMenu,
    ActivityDescription,
    ActivityGroups,
    ActivityCooperativeGroup,
    ActivityArrangers,
} from './components';
import ActivityPreview from './components/ActivityPreview';
import { useAppNavigation } from '../../../_navigator';
import { Theme, useConfirmDiscard, useThemeStyle } from '../../../_utils';
import { useSelectedCoopItem } from '../../../_utils/hooks';
import { parseNullableUnixTimeCodeToDate } from '../../../_utils/misc';
import { ArrayElement, fileSchema } from '../../../types/Utility';

interface ActivityNewProps {
    route: { params?: { activity?: Activity; groups?: number[] } };
}

const schema = z.object({
    id: z.onumber(),

    name: z.string().min(1).max(250),
    start_at: z.date().optional(),
    end_at: z.date().optional().nullable(),

    description: z.string(),
    user_groups: z.array(z.number()),

    last_cancel_at: z.date().nullable(),
    last_confirm_at: z.date().nullable(),

    location: z.string(),

    pictures: z.array(fileSchema),
    payment_account_id: z.string().nullable(),
    paymentStrategy: z.object({
        price: optionalNumberInput,
        mva: optionalNumberInput,
        bookKeepingAccount: z.string(),
    }),
    quantity: optionalNumberInput,
    no_refund: z.boolean(),

    message_type: z.number().min(0).max(5),
    users_type: z.boolean(),
    users: z.array(z.number()),

    cooperative_group_id: z.number().nullable(),

    originallyNoQuantity: z.boolean().optional(),

    arrangers: z.array(z.number()),
});

export type FormValues = z.infer<typeof schema>;

const mapToDefault = (
    initial: Activity | undefined,
    initialGroups: number[] | undefined,
    initialCooperativeGroup: number | null,
    initialBookKeepingAccount: string | undefined,
    initialPaymentAccount: ArrayElement<Cooperative['payment_accounts']> | undefined,
): FormValues => {
    if (initial) {
        return {
            id: initial.id,
            name: initial.title,
            description: initial.description ?? '',
            user_groups: initial.user_groups?.map(({ id }) => id) ?? [],
            end_at: moment.unix(initial.end_at).toDate(),
            start_at: moment.unix(initial.start_at).toDate(),
            location: initial.location ?? '',
            pictures: initial.pictures.map((image, index) => ({
                status: 'stale',
                index,
                ...image,
            })),
            paymentStrategy: { ...initial.paymentStrategy },
            quantity: initial.no_quantity ? '' : initial.max_quantity,
            payment_account_id: initial.payment_account_id,
            message_type: initial.notify_type,
            users_type: initial.users_type === 1,
            users: initial.users.map(({ id }) => id) ?? [],
            no_refund: initial.no_refund,
            last_confirm_at: parseNullableUnixTimeCodeToDate(initial.last_confirm_at),
            last_cancel_at: parseNullableUnixTimeCodeToDate(initial.last_cancel_at),
            cooperative_group_id: initial.cooperative_group_id,
            originallyNoQuantity: initial.no_quantity,
            arrangers: initial.arrangers.map(({ id }) => id),
        };
    }
    return {
        name: '',
        description: '',
        user_groups: initialGroups ?? [],
        location: '',
        pictures: [],
        paymentStrategy: {
            price: 0,
            mva: initialPaymentAccount?.pays_MVA ? 25 : 0,
            bookKeepingAccount: initialBookKeepingAccount ?? '',
        },
        payment_account_id: initialPaymentAccount?.id ?? null,
        quantity: '',
        message_type: (initialGroups?.length ?? 0) > 0 ? 5 : 1,
        users_type: false,
        users: [],
        no_refund: true,
        last_confirm_at: null,
        last_cancel_at: null,
        cooperative_group_id: initialCooperativeGroup ?? null,
        arrangers: [],
    };
};

type screen =
    | 'create'
    | 'preview'
    | 'more'
    | 'pictures'
    | 'description'
    | 'dateTime'
    | 'cost'
    | 'alert'
    | 'location'
    | 'groups'
    | 'cooperative_group'
    | 'arrangers';

const ActivityNew = ({ route }: ActivityNewProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { goBack: internalGoBack } = useAppNavigation();
    const coop = useSelectedCoopItem();

    const formValues = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: mapToDefault(
            route.params?.activity,
            route.params?.groups,
            findInitialCooperativeGroupId(coop?.cooperative_groups ?? []),
            coop?.activity_default_account,
            coop?.payment_accounts.find(Boolean),
        ),
        resolver: zodResolver(schema),
    });

    const { goBack, content } = useConfirmDiscard(
        formValues.formState.isDirty,
        Boolean(route.params?.activity),
        internalGoBack,
    );
    const [page, setCurrentPage] = useState<[screen, screen | undefined]>([
        route.params?.activity ? 'preview' : 'create',
        undefined,
    ]);

    const handleNextPage = () => {
        setCurrentPage(([curr]) => {
            switch (curr) {
                case 'create':
                    return ['preview', curr];
            }
            return ['preview', undefined];
        });
    };

    const handleGoBack = useCallback(() => {
        switch (page[0]) {
            case 'create':
                if (page[1]) {
                    setCurrentPage([page[1], undefined]);
                } else {
                    goBack();
                }
                return;
            case 'preview':
                if (page[1]) {
                    setCurrentPage([page[1], undefined]);
                } else {
                    goBack();
                }
                return;
            case 'more':
                setCurrentPage(['preview', undefined]);
                return;
            case 'pictures':
            case 'description':
            case 'dateTime':
            case 'cost':
            case 'alert':
            case 'location':
            case 'groups':
            case 'cooperative_group':
            case 'arrangers':
                setCurrentPage([page[1] as screen, undefined]);
                return;
        }
    }, [goBack, page]);

    useEffect(() => {
        const handler = () => {
            handleGoBack();
            return true;
        };
        BackHandler.addEventListener('hardwareBackPress', handler);
        return () => BackHandler.removeEventListener('hardwareBackPress', handler);
    }, [handleGoBack]);

    const handleEditProperty = (property: Path<FormValues> | 'more') => {
        switch (property) {
            case 'more': {
                setCurrentPage(['more', undefined]);
                return;
            }
            case 'pictures': {
                setCurrentPage(['pictures', page[0]]);
                return;
            }
            case 'name': {
                setCurrentPage(['create', page[0]]);
                return;
            }
            case 'description': {
                setCurrentPage(['description', page[0]]);
                return;
            }
            case 'start_at':
            case 'end_at':
            case 'last_confirm_at':
            case 'last_cancel_at': {
                setCurrentPage(['dateTime', page[0]]);
                return;
            }
            case 'quantity':
            case 'paymentStrategy.price': {
                setCurrentPage(['cost', page[0]]);
                return;
            }
            case 'message_type': {
                setCurrentPage(['alert', page[0]]);
                return;
            }
            case 'location': {
                setCurrentPage(['location', page[0]]);
                return;
            }
            case 'user_groups': {
                setCurrentPage(['groups', page[0]]);
                return;
            }
            case 'cooperative_group_id': {
                setCurrentPage(['cooperative_group', page[0]]);
                return;
            }
            case 'arrangers': {
                setCurrentPage(['arrangers', page[0]]);
                return;
            }
        }
    };

    return (
        <View style={themedStyle.main}>
            <FormProvider {...formValues}>
                {page[0] === 'create' ? <ActivityAbout nextPage={handleNextPage} goBack={handleGoBack} /> : undefined}
                {page[0] === 'preview' && <ActivityPreview goBack={handleGoBack} onEditProperty={handleEditProperty} />}
                {page[0] === 'pictures' && <ActivityPhoto goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'description' && (
                    <ActivityDescription goBack={handleGoBack} goToPreview={handleNextPage} />
                )}
                {page[0] === 'dateTime' && <ActivityDateTime goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'cost' && <ActivityCost goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'alert' && <ActivityAlert goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'location' && <ActivityLocation goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'groups' && <ActivityGroups goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'cooperative_group' && <ActivityCooperativeGroup goBack={handleGoBack} />}
                {page[0] === 'arrangers' && <ActivityArrangers goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'more' && (
                    <ActivityAddDetailMenu goBack={handleGoBack} onEditProperty={handleEditProperty} />
                )}
            </FormProvider>
            {content}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
    });

export default ActivityNew;
