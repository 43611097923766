import React, { ReactElement, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text } from 'react-native';
import { PageContainer } from './common';
import _fonts from '../../../../_fonts';
import { useThemeStyle, WH, WW } from '../../../../_utils';
import { useSelectedCoopItem } from '../../../../_utils/hooks';
import { Theme } from '../../../../_utils/themeContext';
import { CheckBox, HeaderWithNav, PrimaryButton, FormInput, FormDropdown } from '../../../../Components';
import { FormValues } from '../ActivityNew';

const ActivityCost = ({ goBack, goToPreview }: { goBack(): void; goToPreview(): void }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext<FormValues>();
    const selectedcoop = useSelectedCoopItem();
    const [paymentAccountId, cost] = watch(['payment_account_id', 'paymentStrategy.price']);
    const [isPaid, setIsPaid] = useState(cost ? cost > 0 : false);

    const hasVippsAccount = (selectedcoop?.payment_accounts?.length ?? 0) > 0;
    const selectedPaymentAccount = (selectedcoop?.payment_accounts ?? []).find(
        (account) => account.id === paymentAccountId,
    );

    const handleGoBack = () => {
        if (!isPaid) {
            setValue('paymentStrategy.price', 0);
        }
        goToPreview();
    };

    const { field: noRefundField } = useController({ name: 'no_refund' });
    const handleRefundPress = () => noRefundField.onChange(!noRefundField.value);

    return (
        <>
            <HeaderWithNav title={t('newActivity:indicate_cost')} onPress={goBack} />
            <PageContainer>
                {hasVippsAccount ? (
                    <>
                        <Text style={themedStyle.title}>{t('newActivity:indicate_cost')}</Text>
                        <CheckBox
                            onPress={() => setIsPaid((curr) => !curr)}
                            checked={isPaid}
                            title={t('newActivity:paid_event')}
                            titleStyle={themedStyle.checkBoxText}
                            style={themedStyle.text}
                        />
                        {isPaid && (
                            <>
                                <FormInput
                                    optional
                                    type="number"
                                    name="paymentStrategy.price"
                                    label={t('newActivity:price')}
                                />
                                {selectedPaymentAccount?.pays_MVA ? (
                                    <FormInput type="number" name="paymentStrategy.mva" label={t('newActivity:mva')} />
                                ) : null}
                                {selectedcoop?.enable_bookkeeping ? (
                                    <FormInput
                                        name="paymentStrategy.bookKeepingAccount"
                                        label={t('newActivity:bookKeepingAccount')}
                                    />
                                ) : null}
                                <FormDropdown<FormValues, 'payment_account_id'>
                                    name="payment_account_id"
                                    label={t('newActivity:tax_rate')}
                                    items={(selectedcoop?.payment_accounts ?? []).map((item) => ({
                                        id: item.id,
                                        text: item.name,
                                    }))}
                                />
                                <Text style={themedStyle.text}>{t('newActivity:paymentExplainer')}</Text>
                                <CheckBox
                                    title={t('newActivity:refundCancellations')}
                                    checked={!noRefundField.value}
                                    onPress={handleRefundPress}
                                />
                            </>
                        )}
                    </>
                ) : null}
                <FormInput
                    type="number"
                    name="quantity"
                    label={t('newActivity:attendees_limit')}
                    autoFocus={!hasVippsAccount}
                />
                <Text style={themedStyle.text}>{t('newActivity:enter_zero')}</Text>
                {!hasVippsAccount ? <Text style={themedStyle.text}>{t('newActivity:explainNoCost')}</Text> : null}
            </PageContainer>
            <PrimaryButton text={t('newActivity:continue').toUpperCase()} onPress={handleGoBack} bottomAction="modal" />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        title: {
            marginTop: WW * 0.04,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WH * 0.035,
            color: theme.black,
        },
        text: {
            color: theme.secondaryText,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
        },
        checkBoxText: {
            color: theme.secondaryText,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
        },
    });

export default ActivityCost;
