import React, { ReactElement, useState, useContext, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { useGetNotes } from '_api/useNotes';
import { useOwnProfile } from '_api/useProfile';
import { Support, useGetSupport } from '_api/useSupports';
import { useGetUser, useGetUsers } from '_api/useUsers';
import Images from '_images';
import { useAppNavigation } from '_navigator';
import { fromNowFormat, isAppError, Theme, useIsCoopAdmin, useThemeStyle, WH, WW } from '_utils';
import useUserApartments from '_utils/hooks/useUserApartments';
import { getUsernameFromProfile, isTruthy } from '_utils/misc';
import { screenMargin, subtitleFontSize, titleFontSize, smallestFontSize } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import {
    CacheImage,
    ContextMenu,
    FullWidthPictureCarousel,
    HeaderWithNav,
    HeimeText,
    Icon,
    Loader,
    OpinionatedSafeArea,
    QueryItemView,
    UserListItem,
} from 'Components';
import NotFoundErrorScreen from 'Components/NotFoundErrorScreen';
import { AddCommentModal, ChangeStatusModal, SupportComments, ReportStatus } from './components';

interface ReportSelectedProps {
    route: { params: { supportId: number } };
}

const ReportLoadedGate = ({ route }: ReportSelectedProps): ReactElement => {
    const supportId = route.params.supportId;
    const { data, isLoading, isError, error } = useGetSupport(supportId);

    //To preload users
    useGetUsers(false);

    // Prefecthing
    useGetNotes(supportId);

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        if (isAppError(error) && error.response?.status === 404) {
            return <NotFoundErrorScreen type="Support" />;
        } else {
            throw error;
        }
    }

    if (!data) {
        return <NotFoundErrorScreen type="Support" />;
    }

    return <ReportSelected support={data} />;
};

const ReportSelected = ({ support }: { support: Support }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const creator = useGetUser(support.creator);
    const { refetch: refetchSupport, isRefetching: isRefetchingSupport } = useGetSupport(support.id);
    const {
        refetch: refetchNotes,
        isRefetching: isRefetchingNotes,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
    } = useGetNotes(support.id);
    const isRefetching = isRefetchingSupport || isRefetchingNotes;
    const [modalOpen, setModalOpen] = useState<'moreMenu' | 'addComment' | 'changeStatus' | false>(false);

    const refetch = () => {
        refetchSupport();
        refetchNotes();
    };
    const handleMoreToggle = () => setModalOpen('moreMenu');
    const handleAddCommentOpen = () => setModalOpen('addComment');
    const handleChangeStatus = () => setModalOpen('changeStatus');
    const handleModalDismiss = () => setModalOpen(false);

    const apartments = useUserApartments()(creator);
    const apartmentsString = useMemo(() => {
        return apartments.map(({ name }) => name).join(',');
    }, [apartments]);

    const editReport = () => {
        handleModalDismiss();
        navigate('ReportNew', { support });
    };

    const isCoopAdmin = useIsCoopAdmin();
    const isOwn = support.creator === useOwnProfile()?.data?.id;
    const canDoActions = isOwn || isCoopAdmin;

    return (
        <OpinionatedSafeArea>
            <HeaderWithNav
                action={
                    canDoActions ? (
                        isOwn && !isCoopAdmin ? (
                            <TouchableOpacity onPress={editReport}>
                                <CacheImage resizeMode={'contain'} source={Images.edit} style={themedStyle.editIcon} />
                            </TouchableOpacity>
                        ) : (
                            <ContextMenu
                                actions={
                                    isOwn
                                        ? [
                                              {
                                                  text: t('reportSelected:editReport'),
                                                  onPress: editReport,
                                                  type: 'normal' as const,
                                              },
                                              isCoopAdmin
                                                  ? {
                                                        text: t('reportSelected:changeStatus'),
                                                        onPress: handleChangeStatus,
                                                        type: 'normal' as const,
                                                    }
                                                  : undefined,
                                          ].filter(isTruthy)
                                        : [
                                              {
                                                  text: t('reportSelected:changeStatus'),
                                                  onPress: handleChangeStatus,
                                                  type: 'normal',
                                              },
                                          ]
                                }
                                onToggle={modalOpen === 'moreMenu' ? handleModalDismiss : handleMoreToggle}
                                isOpen={modalOpen === 'moreMenu'}
                                trigger={
                                    <View style={themedStyle.moreButton}>
                                        <Icon name={'moreFilled'} color="black" />
                                    </View>
                                }
                            />
                        )
                    ) : undefined
                }
                title={t('reportSelected:reportDetails')}
                style={themedStyle.navHeader}
            />
            <QueryItemView
                virtualized
                keyboardShouldPersistTaps="handled"
                onRefresh={refetch}
                isRefreshing={isRefetching}
                isLoadingMore={isFetchingNextPage}
                loadMore={hasNextPage ? () => fetchNextPage() : undefined}
            >
                <View style={themedStyle.content}>
                    <FullWidthPictureCarousel
                        onEditPlaceholder={isOwn ? editReport : undefined}
                        pictures={support.pictures}
                    />
                    <View style={themedStyle.itemContent}>
                        <View style={themedStyle.statusContainer}>
                            {isCoopAdmin ? (
                                <TouchableOpacity onPress={handleChangeStatus}>
                                    <ReportStatus large chevron status={support.status} />
                                </TouchableOpacity>
                            ) : (
                                <ReportStatus status={support.status} />
                            )}
                        </View>
                        <HeimeText maxFontSizeMultiplier={2} style={themedStyle.title} selectable>
                            {support.title}
                        </HeimeText>
                        <HeimeText style={themedStyle.timeText}>
                            {fromNowFormat(moment.unix(support.created_at).toDate())}
                        </HeimeText>
                        <View style={themedStyle.separator} />
                        <HeimeText maxFontSizeMultiplier={2} style={themedStyle.description} selectable linkify>
                            {support.content}
                        </HeimeText>
                        <UserListItem
                            title={getUsernameFromProfile(creator)}
                            id={support.creator}
                            desc={apartmentsString}
                            descColor={theme.secondary}
                            noBottomBorder
                        />
                    </View>
                </View>
                <View style={themedStyle.divider} />
                <SupportComments
                    showCommentButton={canDoActions}
                    onAddCommentPress={handleAddCommentOpen}
                    supportId={support.id}
                />
            </QueryItemView>
            {modalOpen === 'addComment' ? (
                <AddCommentModal
                    supportId={support.id}
                    onDismiss={handleModalDismiss}
                    own={isOwn}
                    isPublic={support.is_public}
                    creatorFirstName={creator?.fname ?? ''}
                />
            ) : null}
            {modalOpen === 'changeStatus' ? (
                <ChangeStatusModal
                    supportId={support.id}
                    currentStatus={support.status}
                    onDismiss={handleModalDismiss}
                />
            ) : null}
        </OpinionatedSafeArea>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        content: {
            flexGrow: 1,
        },
        itemContent: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
        },
        navHeader: {
            backgroundColor: theme.mainBackground,
        },
        statusContainer: { alignItems: 'flex-end' },
        moreButton: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            paddingTop: screenMargin / 2,
            paddingBottom: screenMargin / 2,
            minHeight: 40,
        },
        statusTitle: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '100%',
        },
        padding: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            backgroundColor: theme.lightBackground,
        },
        divider: {
            width: '100%',
            height: 5,
            backgroundColor: theme.lightGrey,
        },
        separator: {
            height: WH * 0.02,
        },
        title: {
            fontWeight: 'bold',
            fontSize: titleFontSize,
            color: theme.darkGray,
            flex: 1,
        },
        timeText: {
            fontSize: smallestFontSize,
            color: theme.secondaryLight,
        },
        description: {
            fontSize: subtitleFontSize,
            color: theme.secondaryText,
        },
        editIcon: {
            width: WW * 0.08,
            height: WW * 0.08,
            marginRight: screenMargin,
            marginTop: screenMargin / 2,
            marginBottom: screenMargin / 2,
        },
    });

export default ReportLoadedGate;
