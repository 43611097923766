import { useCallback, useEffect, useMemo } from 'react';
import {
    InfiniteData,
    QueryClient,
    UseInfiniteQueryResult,
    UseMutationResult,
    useMutation,
} from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useGetAccessTokenHeader } from '_utils/Axios';
import { useLaravelInfinteQuery } from './utility';
import { flattenIniniteResult } from '../_utils/misc';
import { useSelectedCoop } from '../SelectedCoop';
import { DeletedUser, ExternalUserProfile, deletedUserSchema, getDeletedUserFallbackName } from '../types/User';
import { LaravelPagingResponse } from '../types/Utility';

const invalidateUsersQuery = (queryClient: QueryClient): void => {
    queryClient.invalidateQueries({ queryKey: ['users'] });
    queryClient.invalidateQueries({ queryKey: ['user'] });
    queryClient.invalidateQueries({ queryKey: ['deleted_users'] });
};

const useGetUsers = (
    onlyInternal: boolean,
    with_groups = true,
): UseInfiniteQueryResult<InfiniteData<ExternalUserProfile[]>, string | Error> => {
    const selectedCoopId = useSelectedCoop();
    return useLaravelInfinteQuery(
        ['users', selectedCoopId, onlyInternal, with_groups],
        async ({ pageParam = 1, getAuthHeader }) =>
            await axios.get<LaravelPagingResponse<ExternalUserProfile[]>>(`cooperatives/${selectedCoopId}/users`, {
                headers: { authorization: await getAuthHeader() },
                params: {
                    page: pageParam,
                    with_groups: onlyInternal ? 0 : with_groups ? 1 : 0,
                    neighbor: onlyInternal ? 1 : undefined,
                },
            }),
        {
            gcTime: Infinity,
            staleTime: 1000 * 60 * 60 * 24 * 7,
        },
    );
};

let pageFetching = false;

const useUserData = () => {
    const { data, isFetchingNextPage, fetchNextPage, hasNextPage } = useGetUsers(false);

    const users = useMemo(() => flattenIniniteResult(data), [data]);

    pageFetching = isFetchingNextPage;

    useEffect(() => {
        if (hasNextPage && !pageFetching) {
            pageFetching = true;
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);
    return users;
};

let pageFetchingDeletedUsers = false;

const useDeletedUsersData = () => {
    const { data, isFetchingNextPage, fetchNextPage, hasNextPage } = useGetDeletedUsers();

    const users = useMemo(() => flattenIniniteResult(data), [data]);

    pageFetchingDeletedUsers = isFetchingNextPage;

    useEffect(() => {
        if (hasNextPage && !pageFetchingDeletedUsers) {
            pageFetchingDeletedUsers = true;
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);
    return users;
};

const useGetUser = (userId: number | undefined) => {
    const findUser = useFindUser();

    return useMemo(() => findUser(userId), [findUser, userId]);
};

type CooperativeUserResult = ExternalUserProfile | DeletedUser | null;

const useFindUser = (): ((search: number | undefined) => CooperativeUserResult) => {
    const users = useUserData();
    const deletedUsers = useDeletedUsersData();
    const { t } = useTranslation();

    return useCallback(
        (userId: number | undefined) => {
            if (!userId) {
                return null;
            }

            const existingUser = users.find((item) => item.id === userId);
            if (existingUser) {
                return existingUser;
            }
            const deletedUser = deletedUsers.find((item) => item.user_id === userId);
            if (deletedUser) {
                const fallbackName = getDeletedUserFallbackName(deletedUser, t);
                return {
                    ...deletedUser,
                    fname: deletedUser.fname ?? fallbackName,
                    lname: deletedUser.lname ?? '',
                };
            }
            return null;
        },
        [deletedUsers, t, users],
    );
};

const useCheckExistUser = (): UseMutationResult<
    { id: number; fname: string; lname: string; phone: string; has_email: boolean } | null,
    string | Error,
    { phone: string }
> => {
    const getAuthHeader = useGetAccessTokenHeader();

    return useMutation({
        mutationFn: async ({ phone }) => {
            const result = await axios.get<{
                success: boolean;
                user: { id: number; fname: string; lname: string; phone: string; has_email: boolean } | null;
            }>(`users/${phone}`, {
                headers: { authorization: await getAuthHeader() },
            });
            if (!result.data.success) {
                throw new Error('Result returned with success == false');
            }
            return result.data.user;
        },
    });
};
const deletedUsersListSchema = z.array(deletedUserSchema);

const useGetDeletedUsers = (): UseInfiniteQueryResult<
    InfiniteData<z.infer<typeof deletedUsersListSchema>>,
    string | Error
> => {
    const selectedCoopId = useSelectedCoop();
    return useLaravelInfinteQuery(
        ['deleted_users', selectedCoopId],
        async ({ pageParam = 1, getAuthHeader }) => {
            const result = await axios.get<LaravelPagingResponse<z.infer<typeof deletedUsersListSchema>>>(
                `cooperatives/${selectedCoopId}/deletedusers`,
                {
                    headers: { authorization: await getAuthHeader() },
                    params: {
                        page: pageParam,
                    },
                },
            );
            return result;
        },
        {
            gcTime: Infinity,
            staleTime: 1000 * 60 * 60 * 24 * 7,
        },
        deletedUsersListSchema,
    );
};

export {
    useGetUsers,
    invalidateUsersQuery,
    useGetUser,
    useFindUser,
    useCheckExistUser,
    useGetDeletedUsers,
    useUserData,
};
