import { useMemo } from 'react';
import Clipboard from '@react-native-clipboard/clipboard';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { showToast } from '_utils';
import { openURL } from '_utils/url';
import { Notification } from 'types/Notification';
import { useAppNavigation } from '../../_navigator';

const useHandleNotificationClick = () => {
    const { navigate } = useAppNavigation();
    const { t } = useTranslation();

    return useMemo(
        () => ({
            handleChatPress: (chatId: number) => {
                navigate('ChatSelected', { chatId: `${chatId}` });
            },
            handleActivityPress: (activityId: number) => {
                navigate('ActivitySelected', { activityId: `${activityId}` });
            },
            handleGroupPress: (groupId: number) => {
                navigate('GroupSelected', { groupId: groupId, isOnboarding: false });
            },
            handleReportedMessagePress: (id: number) => {
                navigate('ReportSelected', { supportId: id });
            },
            handleMessageSelectedPress: (id: number) => {
                navigate('SharingSelected', { messageId: id });
            },
            handleMessageCommentPress: (messageId: number) => {
                navigate('SharingSelected', { messageId, scrollToBottom: 'true' });
            },
            handleSurveyPress: (id: string) => {
                navigate('SurveySelected', { surveyId: id });
            },
            handleUserRegistrationPress: async (url: string) => {
                let result = false;
                try {
                    result = await openURL(url, t, false);
                } finally {
                    if (!result) {
                        showToast({
                            type: 'error',
                            header: t('global:open_admin_error'),
                            text: t('global:open_admin_error_text'),
                            visibilityTime: 10000,
                            position: 'bottom',
                        });
                        Clipboard.setString(url);
                    }
                }
            },
            handleRateHeimePress: () => {
                navigate('RateHeime', undefined);
            },
            handleBookingNotificationPress: (item: Extract<Notification, { type: 'BookingNotification' }>) => {
                if (moment(item.booking_end).isAfter(moment())) {
                    // Is not in the past
                    if (
                        item.procedure_to_do &&
                        moment(item.booking_start).isBefore(moment()) &&
                        moment(item.booking_end).isAfter(moment())
                    ) {
                        // Booking is ongoing
                        navigate('Procedure', {
                            procedureId: item.procedure_to_do,
                            bookingId: parseInt(item.booking_id + '', 10),
                            productName: item.product_name,
                        });
                    } else {
                        navigate('ReservationSelected', { id: item.booking_id + '', initiallyUnlockLock: false });
                    }
                    return;
                }
                navigate('Notifications', undefined);
            },
        }),
        [navigate, t],
    );
};

export default useHandleNotificationClick;
