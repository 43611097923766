import React, { ReactElement, useCallback, useMemo, useContext } from 'react';
import {
    ActivityIndicator,
    FlatList,
    ListRenderItem,
    StyleProp,
    StyleSheet,
    View,
    ViewStyle,
    ViewToken,
} from 'react-native';
import { Theme, useThemeStyle, isWeb, useVisualRefetchState, WH } from '_utils';
import { ThemeContext } from '_utils/themeContext';
import Loader from 'Components/Loader';

interface ItemWithId {
    id: number | string;
}

export interface QueryViewProps<ItemType extends ItemWithId> {
    onViewableItemsChanged?:
        | ((info: { viewableItems: Array<ViewToken>; changed: Array<ViewToken> }) => void)
        | null
        | undefined;
    viewabilityConfig?: { viewAreaCoveragePercentThreshold: number };
    data: ItemType[];
    renderItem: ListRenderItem<ItemType>;
    loadMore?: () => void;
    isLoadingMore?: boolean;
    onRefresh?: () => void;
    isRefreshing?: boolean;
    style?: StyleProp<ViewStyle>;
    containerStyle?: StyleProp<ViewStyle>;
    numColumns?: number;
    /**
     * Apply some default styling
     */
    styled?: boolean;
    emptyList?: ReactElement;
    top?: ReactElement;
    stickyTop?: boolean;
    keyboardShouldPersistTaps?: boolean;
    isSearching?: boolean;
    columnWrapperStyle?: StyleProp<ViewStyle>;
    footer?: ReactElement;
}

const QueryView = <ItemType extends ItemWithId>({
    onViewableItemsChanged,
    viewabilityConfig,
    data,
    renderItem,
    isLoadingMore,
    loadMore,
    isRefreshing,
    onRefresh,
    style,
    containerStyle,
    numColumns,
    styled,
    emptyList,
    top,
    stickyTop,
    keyboardShouldPersistTaps,
    isSearching,
    columnWrapperStyle,
    footer,
}: QueryViewProps<ItemType>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const [refreshing, handleRefresh] = useVisualRefetchState(isRefreshing ?? false, onRefresh ?? (() => {}));
    const keyExtractor = useCallback((item: ItemType, index: number) => `${item.id ?? index}`, []);

    const footerComponent = useMemo(() => {
        if (isLoadingMore) {
            return <ActivityIndicator color={theme.black} />;
        } else {
            return footer ?? <View />;
        }
    }, [footer, isLoadingMore, theme.black]);

    const handleOnEndReached = loadMore ? () => loadMore() : undefined;

    return (
        <FlatList
            keyboardShouldPersistTaps={keyboardShouldPersistTaps ? 'handled' : undefined}
            onViewableItemsChanged={onViewableItemsChanged}
            viewabilityConfig={viewabilityConfig}
            style={[styled ? themedStyle.styledMain : undefined, themedStyle.main, style]}
            contentContainerStyle={[styled ? themedStyle.styledContentContainer : undefined, containerStyle]}
            data={isSearching ? [] : data}
            showsVerticalScrollIndicator={isWeb()}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            initialNumToRender={5}
            onEndReached={handleOnEndReached}
            ListFooterComponent={footerComponent}
            onEndReachedThreshold={0.5}
            onRefresh={onRefresh ? handleRefresh : undefined}
            refreshing={refreshing}
            columnWrapperStyle={
                numColumns && numColumns > 1 ? (columnWrapperStyle ?? themedStyle.columnWrapperStyle) : undefined
            }
            numColumns={numColumns}
            ListEmptyComponent={
                isSearching ? (
                    <View style={themedStyle.fullHeight}>
                        <Loader />
                    </View>
                ) : (
                    emptyList
                )
            }
            ListHeaderComponent={top}
            stickyHeaderIndices={stickyTop ? [0] : undefined}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },
        fullHeight: {
            height: '100%',
        },
        styledMain: {
            backgroundColor: theme.shadow,
        },
        styledContentContainer: { paddingBottom: WH * 0.01, paddingTop: WH * 0.01 },
        columnWrapperStyle: { flex: 1, justifyContent: 'flex-start' },
    });

export default QueryView;
