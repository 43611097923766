import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOwnProfile } from '_api/useProfile';
import { isAppError, isNetworkError, showToast } from '_utils';
import { useCreateActivity, useEditActivity } from '../../../../../_api/useActivities';
import { useAppNavigation } from '../../../../../_navigator';
import { FormValues } from '../../ActivityNew';

const useHandleSubmit = (): { onSubmit(): void; isLoading: boolean } => {
    const { mutate: editActivity, isPending: isUpdating } = useEditActivity();
    const { mutate: createActivity, isPending: isCreating } = useCreateActivity();
    const { navigate } = useAppNavigation();
    const { t } = useTranslation();

    const ownId = useOwnProfile()?.data?.id ?? 0;

    const { handleSubmit } = useFormContext<FormValues>();

    const onSubmit = useCallback(
        (values: FormValues) => {
            const quantity = (values.quantity as number) ?? 0;
            const mapped = {
                ...values,
                no_quantity: values.quantity === undefined || values.quantity === '',
                quantity: Math.max(0, quantity),
                active: true,
                start_at: values.start_at as Date,
                end_at: values.end_at as Date,
                arrangers: Array.from(new Set([...values.arrangers, ownId])),
                paymentStrategy: {
                    ...values.paymentStrategy,
                    price: values.paymentStrategy.price ? values.paymentStrategy.price : 0,
                    mva: values.paymentStrategy.mva ? values.paymentStrategy.mva : 0,
                },
            };

            const navigateTo = (activityId: number) => navigate('ActivitySelected', { activityId: `${activityId}` });
            const onError = (e: unknown) => {
                if (!(isAppError(e) && isNetworkError(e))) {
                    showToast({
                        type: 'error',
                        header: t('global:task_failed_header'),
                        text: t('global:task_failed_description'),
                        position: 'bottom',
                    });
                }
            };

            if (values.id) {
                editActivity([values.id, mapped], { onSuccess: () => navigateTo(values.id as number), onError });
            } else {
                createActivity(mapped, {
                    onSuccess: ({ success }) => navigateTo(success),
                    onError,
                });
            }
        },
        [createActivity, editActivity, navigate, ownId, t],
    );

    return useMemo(
        () => ({ onSubmit: handleSubmit(onSubmit), isLoading: isUpdating || isCreating }),
        [handleSubmit, isCreating, isUpdating, onSubmit],
    );
};

export default useHandleSubmit;
