import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { z } from 'zod';
import Images from '_images';
import { useAppNavigation } from '_navigator';
import { showToast, WH } from '_utils';
import { track } from '_utils/Amplitude';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { CacheImage, HeaderWithNav, HeimeText, PrimaryButton, SecondaryButton } from 'Components';
import { ArrayElement, RoutePropsSchema } from 'types/Utility';
import { suggestions } from '../common/suggestions';

const suggestionLiterals = suggestions.map((s) => z.literal(s));
type suggestionLiteral = ArrayElement<typeof suggestionLiterals>;
const routeParams = RoutePropsSchema(
    z.object({
        suggestion: z.union(suggestionLiterals as [suggestionLiteral, suggestionLiteral, ...suggestionLiteral[]]),
    }),
);

const ActivitySuggestionSelected = ({
    route: {
        params: { suggestion },
    },
}: z.infer<typeof routeParams>): ReactElement => {
    const { t } = useTranslation();
    const insets = useSafeAreaInsets();
    const { navigate } = useAppNavigation();
    const [isInterested, setIsInterested] = useState(false);

    useEffect(() => {
        track('Viewing activity suggestion', { suggestion });
    }, [suggestion]);

    const handleInterest = () => {
        track('Pressed interested to attend', { suggestion });
        setIsInterested(true);
    };
    const handleArrange = () => {
        track('Pressed wants to arrange', { suggestion });
        showToast({
            header: t('activity:suggestions:arrange_toast_header'),
            text: t(`activity:suggestions:${suggestion}_arrange_description`),
            type: 'success',
            visibilityTime: 10000,
        });
        navigate('ActivityNew', undefined);
    };

    return (
        <View style={{ height: '100%' }}>
            <HeaderWithNav title={t(`activity:suggestions:${suggestion}_title`)} />
            <ScrollView
                style={{ height: '100%', flex: 1 }}
                contentContainerStyle={{
                    paddingHorizontal: screenMargin,
                    gap: screenMargin,
                }}
            >
                <CacheImage
                    source={Images[suggestion]}
                    style={{ width: '100%', height: WH * 0.3 }}
                    resizeMode="cover"
                />
                <HeimeText variant="title">{t(`activity:suggestions:${suggestion}_title`)}</HeimeText>
                <HeimeText variant="subtitle">{t(`activity:suggestions:${suggestion}_description`)}</HeimeText>
                <HeimeText variant="subtitle">{t(`activity:suggestions:${suggestion}_arrange_description`)}</HeimeText>
            </ScrollView>
            <View
                style={{
                    paddingBottom: Math.max(insets.bottom, screenMargin),
                    gap: smallestMargin,
                    paddingHorizontal: screenMargin,
                }}
            >
                {suggestion === 'other' ? null : (
                    <PrimaryButton
                        text={isInterested ? t('activity:suggestions:interested') : t('activity:suggestions:interest')}
                        onPress={handleInterest}
                        status={isInterested ? 'disabled' : null}
                    />
                )}
                <SecondaryButton text={t('activity:suggestions:arrange')} onPress={handleArrange} />
            </View>
        </View>
    );
};

export default ActivitySuggestionSelected;
