import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnixTimeCode } from '../types/Utility';

export type AddProfileModalState = {
    /**
     * The UNIX time  when the modal should be shown again.
     */
    showAfter: UnixTimeCode | null;
};

const initialState: AddProfileModalState = { showAfter: null };

const addProfileModalSlice = createSlice({
    name: 'addProfileModal',
    initialState,
    reducers: {
        dismissAddProfile: (state, action: PayloadAction<UnixTimeCode>) => {
            if (state.showAfter && state.showAfter > action.payload) {
                return;
            }
            state.showAfter = action.payload;
        },
    },
});

export const { dismissAddProfile } = addProfileModalSlice.actions;

export default addProfileModalSlice.reducer;
