import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { MenuProvider } from 'react-native-popup-menu';
import { useDispatch } from 'react-redux';
import { useGetRegistration, useDeleteRegistration } from '_api/useRegistrations';
import { SafeAreaView } from '_dependencies/safeArea';
import { reset } from '_redux/pendingRegistration';
import { Theme, WH, WW, useThemeStyle } from '_utils';
import { screenMargin, smallestFontSize, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import { useAuthNavigation } from 'Auth/AuthNavigation';
import {
    HeimeText,
    Icon,
    ContextMenu,
    SecondaryButton,
    Footer,
    Modal,
    Loader,
    PrimaryButton,
    QueryItemView,
} from 'Components';
import ContactModal from './ContactModal';
import Title from './Title';

const RegistrationSelected = ({ registrationId }: { registrationId: string }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const navigation = useAuthNavigation();
    const [overlayStatus, setOverlayStatus] = useState<null | 'menu' | 'cancel' | 'contact'>(null);
    const dispatch = useDispatch();

    const { data, isLoading, isError, error, isFetching, refetch } = useGetRegistration(registrationId);
    const { isPending: isCancelling, mutate } = useDeleteRegistration(registrationId);

    const handleGoToLogin = () => {
        dispatch(reset());
        navigation.navigate('EnterPhone');
    };

    const handleGoToEnterPassword = () => {
        dispatch(reset());
        // We assume the user has not set a pin since they just got an account
        navigation.navigate('EnterPhone', { phoneHint: data?.phone ?? '' });
    };

    const handleContactUs = () => {
        setOverlayStatus('contact');
    };

    const handleOpenCancelModal = () => {
        setOverlayStatus('cancel');
    };
    const handleEditRegistration = () => {
        if (data) {
            navigation.navigate('RegistrationEdit', { data: data, registrationId });
        }
    };
    const cancelRegistration = () => {
        mutate(undefined, {
            onSuccess: () => {
                dispatch(reset());
                navigation.navigate('EnterPhone');
            },
        });
    };

    const { theme } = useContext(ThemeContext);
    if (isError) {
        throw error;
    }

    const statusSetup =
        data?.status === 'pending'
            ? {
                  statusContent: (
                      <>
                          <Icon name="loader" scale={1.5} color="black" />
                          <HeimeText style={themedStyle.status}>{t('registrationSelected:waiting')}</HeimeText>
                      </>
                  ),
                  backgroundColor: theme.lightGrey,
                  icon: 'hourGlass' as const,
                  title: t('registrationSelected:titlePending'),
                  subText: t('registrationSelected:subTextPending'),
                  button: (
                      <SecondaryButton
                          style={themedStyle.secondary}
                          onPress={handleEditRegistration}
                          text={t('registrationSelected:update')}
                      />
                  ),
              }
            : data?.status === 'approved'
              ? {
                    statusContent: (
                        <HeimeText style={themedStyle.status}>{t('registrationSelected:approved')}</HeimeText>
                    ),
                    backgroundColor: theme.lightGreen,
                    icon: 'celebrate' as const,
                    title: t('registrationSelected:titleApproved'),
                    subText: t('registrationSelected:subTextApproved'),
                    button: (
                        <PrimaryButton
                            bottomAction="modal"
                            text={t('registrationSelected:proceed')}
                            onPress={handleGoToEnterPassword}
                        />
                    ),
                }
              : {
                    statusContent: (
                        <HeimeText style={themedStyle.status}>{t('registrationSelected:rejected')}</HeimeText>
                    ),
                    backgroundColor: theme.transparent_red,
                    icon: 'stop' as const,

                    title: t('registrationSelected:titleRejected'),
                    subText: t('registrationSelected:subTextRejected', {
                        neighborhood: data?.assigned_cooperative?.name ?? data?.cooperative_guess,
                        apartment: data?.assigned_apartment?.code ?? data?.apartment_guess,
                    }),
                    button: (
                        <View style={themedStyle.secondary}>
                            <View style={themedStyle.bubble}>
                                <HeimeText style={themedStyle.author}>{data?.reason_author?.name}</HeimeText>
                                <HeimeText style={themedStyle.reason}>{data?.reason}</HeimeText>
                            </View>
                            <View style={themedStyle.flexRow}>
                                <SecondaryButton
                                    style={themedStyle.flexGrow}
                                    text={t('registrationSelected:contact')}
                                    onPress={handleContactUs}
                                />
                                <PrimaryButton
                                    style={themedStyle.flexGrow}
                                    text={t('registrationSelected:tryAgain')}
                                    onPress={handleGoToLogin}
                                />
                            </View>
                        </View>
                    ),
                };

    return (
        <MenuProvider>
            <SafeAreaView style={themedStyle.container}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <QueryItemView isRefreshing={isFetching} onRefresh={refetch} scrollEnabled>
                            <View style={themedStyle.flexRow}>
                                <View style={themedStyle.minButton} />
                                <View style={[themedStyle.flexRow, themedStyle.flex]}>
                                    <HeimeText style={themedStyle.statusLabel}>
                                        {t('registrationSelected:status')}
                                    </HeimeText>
                                    {statusSetup.statusContent}
                                </View>
                                {data?.status === 'pending' ? (
                                    <ContextMenu
                                        actions={[
                                            {
                                                loading: isCancelling,
                                                text: t('registrationSelected:cancel'),
                                                onPress: handleOpenCancelModal,
                                                type: 'danger',
                                            },
                                        ]}
                                        isOpen={overlayStatus === 'menu'}
                                        onToggle={() => setOverlayStatus((curr) => (curr === 'menu' ? null : 'menu'))}
                                        trigger={
                                            <View style={themedStyle.minButton}>
                                                <Icon name="moreFilled" color="black" />
                                            </View>
                                        }
                                    />
                                ) : (
                                    <View style={themedStyle.minButton} />
                                )}
                            </View>
                            <View style={themedStyle.centered}>
                                <View
                                    style={[
                                        themedStyle.timeContainer,
                                        { backgroundColor: statusSetup.backgroundColor },
                                    ]}
                                >
                                    <Icon name={statusSetup.icon} scale={1.5} color="main" />
                                </View>
                                <View>
                                    <Title style={themedStyle.title} text={statusSetup.title} />
                                    <HeimeText style={themedStyle.subTitle}>{statusSetup.subText}</HeimeText>
                                </View>
                            </View>
                        </QueryItemView>
                        <View>
                            {statusSetup.button}
                            <Footer />
                        </View>
                    </>
                )}
            </SafeAreaView>
            {overlayStatus === 'cancel' ? (
                <Modal
                    header={t('registrationSelected:confirmCancel')}
                    buttons={[
                        {
                            text: t('global:no'),
                            onPress: () => setOverlayStatus(null),
                        },
                        {
                            text: t('global:yes'),
                            onPress: cancelRegistration,
                            type: 'danger',
                            isLoading: isCancelling,
                        },
                    ]}
                    onDismiss={() => setOverlayStatus(null)}
                />
            ) : overlayStatus === 'contact' ? (
                <ContactModal onDismiss={() => setOverlayStatus(null)} />
            ) : null}
        </MenuProvider>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            height: '100%',
            backgroundColor: theme.mainBackground,
            justifyContent: 'space-between',
        },
        centered: {
            alignItems: 'center',
            justifyContent: 'center',
            marginHorizontal: screenMargin,
        },
        secondary: {
            marginHorizontal: screenMargin,
            marginBottom: screenMargin,
        },
        flexGrow: { flexGrow: 1 },
        title: {
            paddingTop: smallestMargin,
            paddingBottom: screenMargin * 2,
            textAlign: 'center',
        },
        subTitle: {
            fontSize: subtitleFontSize,
            color: theme.black,
        },
        flexRow: {
            flexDirection: 'row',
            gap: smallestMargin,
            alignItems: 'center',
            marginBottom: screenMargin,
        },
        flex: {
            flexGrow: 1,
            justifyContent: 'center',
        },
        minButton: { width: WW * 0.1 },
        statusLabel: {
            fontWeight: 'bold',
            fontSize: subtitleFontSize,
        },
        status: {
            fontSize: subtitleFontSize,
        },
        timeContainer: {
            width: WW * 0.5,
            height: WW * 0.5,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: WH * 0.5,
        },
        bubble: {
            width: '100%',
            borderWidth: 1,
            borderRadius: smallestFontSize,
            borderColor: theme.lightGrey,
            padding: screenMargin,
            marginBottom: screenMargin,
            borderBottomRightRadius: 0,
        },
        author: {
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            color: theme.main,
            marginBottom: smallestMargin,
        },
        reason: {
            fontSize: subtitleFontSize,
        },
    });

export default RegistrationSelected;
