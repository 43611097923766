import React, { PropsWithChildren, ReactElement } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { screenMargin } from '../../../../../_utils/sizes';

const PageContainer = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    return (
        <ScrollView style={styles.content} keyboardShouldPersistTaps="always">
            {children}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    content: {
        flex: 1,
        paddingLeft: screenMargin,
        paddingRight: screenMargin,
        paddingBottom: screenMargin,
    },
});

export default PageContainer;
