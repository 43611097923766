import React, { ReactElement } from 'react';
import { Modal as RNModal, StyleSheet, View, Dimensions } from 'react-native';
import { MenuProvider } from 'react-native-popup-menu';
import ModalContent from './ModalContent';
import { ModalProps } from './types';
import { Theme, useThemeStyle, WW } from '../../_utils';

const Modal = (props: ModalProps): ReactElement => {
    const { onDismiss, style } = props;
    const themedStyle = useThemeStyle(styles);

    return (
        <RNModal transparent onDismiss={onDismiss} onRequestClose={onDismiss}>
            <View style={themedStyle.nativeModal}>
                <View style={themedStyle.cover} />
                <View style={[themedStyle.modal, style]}>
                    <MenuProvider skipInstanceCheck>
                        <ModalContent {...props} />
                    </MenuProvider>
                </View>
            </View>
        </RNModal>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        nativeModal: {
            width: Dimensions.get('window').width,
            height: Dimensions.get('window').height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        cover: {
            width: Dimensions.get('window').width,
            height: Dimensions.get('window').height,
            backgroundColor: theme.black,
            opacity: 0.4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modal: {
            backgroundColor: theme.mainBackground,
            position: 'absolute',
            opacity: 1,
            overflow: 'visible',
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 16,
            paddingBottom: 16,
            width: 'auto',
            minWidth: 360,
            maxWidth: WW,
        },
    });

export default Modal;
