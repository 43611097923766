import React, { PropsWithChildren, ReactElement } from 'react';
import { ParamListBase, RouteProp, useRoute } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import useThemeStyle from '_utils/hooks/useThemeStyle';
import { Theme } from '_utils/themeContext';
import HeaderWithNav from 'Components/HeaderWithNav';

const StackHeader = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const { t } = useTranslation();
    const route = useRoute<{ params: { type: string } } & RouteProp<ParamListBase, string>>();
    const themedStyle = useThemeStyle(Style);

    return (
        <>
            <HeaderWithNav
                title={
                    route?.params?.type === 'add'
                        ? t('chatInfo:addMember').toLocaleUpperCase()
                        : t('chatAll:createNewChat').toLocaleUpperCase()
                }
                style={themedStyle.header}
            />
            {children}
        </>
    );
};
const Style = (theme: Theme) => StyleSheet.create({ header: { backgroundColor: theme.mainBackground } });

export default StackHeader;
