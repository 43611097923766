import React, { PropsWithChildren, ReactElement, useContext } from 'react';
import { View, StyleSheet, TouchableOpacity, ViewStyle, StyleProp } from 'react-native';
import HeimeText from './HeimeText';
import { Theme, useThemeStyle, WW } from '../_utils';
import { ThemeContext } from '../_utils/themeContext';

interface RadioButtonProps {
    title?: string;
    style?: StyleProp<ViewStyle>;
    checked: boolean;
    onPress(): void;
}

const RadioButton = ({
    style,
    onPress,
    checked,
    title,
    children,
}: PropsWithChildren<RadioButtonProps>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    let mainStyles = [themedStyle.container, style];

    return (
        <TouchableOpacity style={mainStyles} onPress={onPress}>
            <View
                style={[
                    themedStyle.checkbox,

                    {
                        borderColor: checked ? theme.main : theme.secondaryLight,
                        backgroundColor: checked ? theme.main : theme.mainBackground,
                        borderWidth: 1,
                    },
                ]}
            >
                {checked && <View style={themedStyle.check} />}
            </View>
            {title && (
                <HeimeText maxFontSizeMultiplier={2} style={themedStyle.title}>
                    {title}
                </HeimeText>
            )}
            {children}
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            alignItems: 'center',
            minHeight: 40,
        },
        checkbox: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.mainBackground,
            borderRadius: (WW * 0.05) / 2,
            width: WW * 0.05,
            height: WW * 0.05,
        },
        title: {
            marginLeft: WW * 0.02,
            fontSize: WW * 0.04,
            flex: 1,
        },
        check: {
            width: WW * 0.022,
            height: WW * 0.022,
            backgroundColor: theme.mainBackground,
            borderRadius: (WW * 0.022) / 2,
        },
    });

export default RadioButton;
