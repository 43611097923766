import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import { smallestMargin, screenMargin, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { Icon, HeimeText } from 'Components';
import { ArrayElement } from 'types/Utility';
import { suggestions } from './suggestions';

const IconMap: Record<ArrayElement<typeof suggestions>, ComponentProps<typeof Icon>['name']> = {
    walk: 'map',
    coffee: 'coffee',
    party: 'glassWine',
    christmas_lighting: 'bulb',
    bbq: 'sun',
    outdoor_cinema: 'filmBoard',
    sport: 'tv',
    common_dinner: 'cutlery2',
    knitting: 'users',
    bathing: 'bathing',
    community_effort: 'briefCase',
    workout: 'dumbbell',
    boardgames: 'games',
    other: 'activity',
};

const ActivitySuggestionItem = ({
    onPress,
    suggestion,
}: {
    onPress(): void;
    suggestion?: ArrayElement<typeof suggestions>;
}) => {
    const { t } = useTranslation();
    const { theme } = useThemeContext();

    const buttonStyle = {
        borderRadius: 8,
        flexDirection: 'row',
        gap: smallestMargin,
        paddingVertical: smallestMargin,
        paddingHorizontal: screenMargin,
        backgroundColor: theme.lightGreen,
        alignItems: 'center',
        width: '100%',
    } as const;
    const textStyle = {
        color: theme.main,
        fontSize: subtitleFontSize,
    };

    if (suggestion) {
        return (
            <TouchableOpacity onPress={onPress} style={buttonStyle}>
                <Icon name={IconMap[suggestion]} color="main" />
                <HeimeText style={[textStyle, { flexGrow: 1 }]}>
                    {t(`activity:suggestions:${suggestion}_title`)}
                </HeimeText>
                <Icon name="chevron" color="main" />
            </TouchableOpacity>
        );
    }
    return (
        <TouchableOpacity onPress={onPress} style={[buttonStyle, { justifyContent: 'center' }]}>
            <HeimeText style={textStyle}>{t('activity:suggestions:direct_press_create')}</HeimeText>
            <Icon name="plus" color="main" />
        </TouchableOpacity>
    );
};

export default ActivitySuggestionItem;
