const Images = {
    defaultAvatar: require('../../resources/images/avatar.png'),
    boardSection: require('../../resources/images/Styret.png'),
    reservationSection: require('../../resources/images/Reservations.png'),
    no_result: require('../../resources/images/noresult.png'),
    sharingWall: require('../../resources/images/sharingWall.png'),
    failWall: require('../../resources/images/failWall.png'),
    chevron: require('../../resources/images/next.png'),
    logo: require('../../resources/images/logo_final.png'),
    edit: require('../../resources/images/edit.png'),
    tent: require('../../resources/images/tent.png'),
    pointing: require('../../resources/images/pointing.png'),
    notificationPopup: require('../../resources/images/notificationPopup.png'),
    notificationPopupNo: require('../../resources/images/notificationPopupNo.png'),
    createProfile: require('../../resources/images/createProfile.png'),
    bbq: require('../../resources/images/activitySuggestions/bbq.jpg'),
    christmas_lighting: require('../../resources/images/activitySuggestions/christmas_lighting.jpeg'),
    coffee: require('../../resources/images/activitySuggestions/coffee.jpg'),
    common_dinner: require('../../resources/images/activitySuggestions/common_dinner.jpg'),
    community_effort: require('../../resources/images/activitySuggestions/community_effort.jpg'),
    knitting: require('../../resources/images/activitySuggestions/knitting.jpg'),
    other: require('../../resources/images/activitySuggestions/other.png'),
    outdoor_cinema: require('../../resources/images/activitySuggestions/outdoor_cinema.jpg'),
    party: require('../../resources/images/activitySuggestions/party.jpg'),
    sport: require('../../resources/images/activitySuggestions/sport.jpg'),
    walk: require('../../resources/images/activitySuggestions/walk.jpg'),
    bathing: require('../../resources/images/activitySuggestions/bathing.jpg'),
    boardgames: require('../../resources/images/activitySuggestions/boardgames.jpg'),
    workout: require('../../resources/images/activitySuggestions/workout.jpg'),
    facebook: require('../../resources/images/facebook.png'),
} as const;

export default Images as Record<keyof typeof Images, number>;
