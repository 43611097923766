import React, { ReactElement, useCallback, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, Modal, TouchableOpacity, View, SafeAreaView as SafeAreaViewNative } from 'react-native';
import { useUpdateProfile } from '../../../_api';
import { useGDPRInfo } from '../../../_api/useGDPRInfo';
import { SafeAreaView } from '../../../_dependencies/safeArea';
import _fonts from '../../../_fonts';
import Images from '../../../_images';
import { useAppNavigation } from '../../../_navigator';
import { dismissAddProfile } from '../../../_redux/addProfileModal';
import { isWeb, useThemeStyle, useAppDispatch, WH, WW, Theme } from '../../../_utils';
import { permissions } from '../../../_utils/permission';
import { screenMargin } from '../../../_utils/sizes';
import { CacheImage, Container, HeaderWithNav, PrimaryButton, QueryItemView } from '../../../Components';
import OnboardingStepContainer from '../Components/OnboardingStepContainer';
import OnboardingTitles from '../Components/OnboardingTitles';

interface PrivacyPolicyProps {
    route?: { params?: { isNotOnboarding: 'true' | 'false' } };
}

const PrivacyPolicy = ({ route }: PrivacyPolicyProps): ReactElement => {
    const isNotOnboarding = route?.params?.isNotOnboarding === 'true';

    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data: gdprInfo, isFetching, refetch } = useGDPRInfo();
    const { mutate: updateProfile } = useUpdateProfile();
    const { goBack, navigate } = useAppNavigation();
    const dispatch = useAppDispatch();
    const [showDetail, setShowDetail] = useState<boolean>(false);

    const onCloseModal = useCallback(() => {
        setShowDetail(false);
    }, [setShowDetail]);

    const onOpenModal = useCallback(() => {
        setShowDetail(true);
    }, [setShowDetail]);

    const onContinue = useCallback(async () => {
        // User is onboarding no need to ask them to create profile
        dispatch(dismissAddProfile(moment().add({ days: 2 }).unix()));
        updateProfile({ gdpr: true });
        const permissionStatus = await permissions.check('notifications');
        if (permissionStatus === 'granted' || isWeb()) {
            navigate('ProfileConfirmInfo', undefined);
        } else {
            navigate('AllowPushAlertsStep', undefined);
        }
    }, [dispatch, navigate, updateProfile]);

    const privacyPolicyContent = (
        <>
            <QueryItemView style={themedStyle.container} isRefreshing={isFetching} onRefresh={refetch}>
                <Text maxFontSizeMultiplier={2} style={themedStyle.contentTitle}>
                    {gdprInfo?.title ?? ''}
                </Text>
                <Container style={themedStyle.modalContent}>
                    <Text maxFontSizeMultiplier={2} style={themedStyle.contentModal}>
                        {gdprInfo?.content ?? ''}
                    </Text>
                </Container>
            </QueryItemView>
            <PrimaryButton text={t('global:back')} onPress={() => (isNotOnboarding ? goBack() : onCloseModal())} />
        </>
    );

    if (!isNotOnboarding) {
        return (
            <>
                <OnboardingStepContainer
                    step={1}
                    bottomSticky={
                        <PrimaryButton bottomAction="modal" text={t('onboarding:continue')} onPress={onContinue} />
                    }
                >
                    <QueryItemView style={themedStyle.container} isRefreshing={isFetching} onRefresh={refetch}>
                        <OnboardingTitles
                            title={t('onboarding:privacyPolicyHeader')}
                            subTitle={t('onboarding:privacyPolicyDesciptions')}
                        />
                        <View style={themedStyle.body}>
                            <Text style={themedStyle.content} maxFontSizeMultiplier={2}>
                                {t('onboarding:privacyPolicyContent')}
                            </Text>
                            <View style={themedStyle.containerClick}>
                                <Text style={themedStyle.actionText} maxFontSizeMultiplier={2}>
                                    {t('onboarding:privacyPolicyAgree')}
                                </Text>
                                <TouchableOpacity onPress={onOpenModal}>
                                    <Text maxFontSizeMultiplier={2} style={[themedStyle.actionText, themedStyle.link]}>
                                        {t('onboarding:privacyPolicyLowerCase')}
                                    </Text>
                                </TouchableOpacity>
                                <Text
                                    maxFontSizeMultiplier={2}
                                    style={[themedStyle.actionText, themedStyle.bottomAction]}
                                >
                                    {t('onboarding:privacyPolicyAgreePost')}
                                </Text>
                            </View>
                        </View>
                    </QueryItemView>
                    <Modal animationType="slide" transparent={false} visible={showDetail}>
                        {/* SafeAreaView from 'react-native-safe-area-context' not working */}
                        {/* Replaced react-native-safe-area-context with react-native SafeAreaView  */}
                        <SafeAreaViewNative style={themedStyle.containerDetails}>
                            <TouchableOpacity onPress={onCloseModal}>
                                <CacheImage
                                    resizeMode={'contain'}
                                    style={themedStyle.arrowBack}
                                    source={Images.chevron}
                                />
                            </TouchableOpacity>
                            {privacyPolicyContent}
                        </SafeAreaViewNative>
                    </Modal>
                </OnboardingStepContainer>
            </>
        );
    } else {
        return (
            <SafeAreaView style={themedStyle.safeView} edges={['left', 'right', 'bottom']}>
                <HeaderWithNav title={t('userMenu:privacy')} />
                {privacyPolicyContent}
            </SafeAreaView>
        );
    }
};
export default PrivacyPolicy;

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
        },
        contentTitle: {
            fontFamily: _fonts.primaryFontBold,
            marginTop: WH * 0.02,
        },
        contentModal: {
            fontFamily: _fonts.primaryFont,
            marginBottom: WH * 0.02,
        },
        modalContent: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            backgroundColor: theme.white,
        },
        safeView: { height: '100%', display: 'flex', backgroundColor: theme.mainBackground },
        body: { marginTop: 20, marginLeft: 20, marginRight: 20 },
        content: {
            color: theme.secondaryText,
            fontSize: 16,
            lineHeight: 18,
            fontFamily: _fonts.primaryFont,
        },
        containerClick: { marginTop: 20, marginBottom: 38 },
        actionText: { color: theme.secondaryText, fontSize: 16, fontFamily: _fonts.primaryFont, fontWeight: 'bold' },
        link: { textDecorationLine: 'underline' },
        bottomAction: { marginTop: 16 },
        containerDetails: {
            height: '100%',
            display: 'flex',
        },
        arrowBack: {
            width: WW * 0.04,
            height: WH * 0.04,
            tintColor: theme.main,
            marginRight: WH * 0.01,
            marginLeft: screenMargin,
            transform: [{ rotate: '180deg' }],
        },
    });
