import {
    InfiniteData,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { FileLink } from 'types/Base';
import { useLaravelInfinteQuery } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import { LaravelPagingResponse, UnixTimeCode, GenericCollectionItem } from '../types/Utility';

export interface Note {
    id: number;
    own: boolean;
    creator: GenericCollectionItem;
    content: string;
    created_at: UnixTimeCode;
    files: FileLink[];
    pictures: FileLink[];
}

const useGetNotes = (supportId: number): UseInfiniteQueryResult<InfiniteData<Note[]>, string | Error> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();

    return useLaravelInfinteQuery(
        ['notes', selectedCoopId, supportId],
        async ({ pageParam = 1 }) =>
            await axios.get<LaravelPagingResponse<Note[]>>(
                `cooperatives/${selectedCoopId}/supports/${supportId}/notes`,
                {
                    headers: { authorization: await getAuthHeader() },
                    params: {
                        page: pageParam,
                    },
                },
            ),
        {
            gcTime: Infinity,
            staleTime: 5 * 1000 * 60,
        },
    );
};

export interface MutateNoteBody {
    content: string;
}

const useCreateNote = (): UseMutationResult<{ success: number }, string | Error, [number, MutateNoteBody]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([supportId, note]: [number, MutateNoteBody]) => {
            const result = await axios.post<{ success: number }>(
                `cooperatives/${selectedCoopId}/supports/${supportId}/notes`,
                note,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Note creation return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['notes'],
            });
        },
    });
};

const useEditNote = (): UseMutationResult<{ success: number }, string | Error, [number, number, MutateNoteBody]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([supportId, noteId, note]: [number, number, MutateNoteBody]) => {
            const result = await axios.patch<{ success: number }>(
                `cooperatives/${selectedCoopId}/supports/${supportId}/notes/${noteId}`,
                note,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Note edit return unsuccessful result');
            }
            return result.data;
        },
        onSettled: (_r, _e, [supportId, noteId]) => {
            queryClient.invalidateQueries({
                queryKey: ['notes'],
            });
            queryClient.invalidateQueries({
                queryKey: ['note', selectedCoopId, supportId, noteId],
            });
        },
    });
};

const useDeleteNote = (): UseMutationResult<{ success: boolean }, string | Error, [number, number]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([supportId, noteId]: [number, number]) => {
            const result = await axios.delete<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/supports/${supportId}/notes/${noteId}`,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Note deletetation return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['notes'],
            });
        },
    });
};
export { useGetNotes, useCreateNote, useEditNote, useDeleteNote };
